/* eslint-disable no-console */

import { register } from 'register-service-worker'
console.log('register-service-worker', process.env.NODE_ENV);
if (process.env.NODE_ENV != 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(e) {
      console.log(e);
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered(e) {
      console.log(e);
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      alert("New content is downloading.");
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content is available; please refresh.')

    },
    offline() {
      alert("No internet connection found. App is running in offline mode.");
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })

//
  
}
