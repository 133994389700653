import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user,showBottomBar:true  }
  : { status: { loggedIn: false }, user: null,showBottomBar:false };

export const auth = {
  namespaced: true,
  userz: user,
  state: initialState,
  actions: {
    update({ comit }, user) {
      console.log(comit, user);
                localStorage.setItem('user', JSON.stringify(user));

    },

    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          console.log(user);
          if (user.error)
          {
            alert("Erreur :" + user.error);
            commit('loginFailure');
          return Promise.reject(user.error);
            return false;
          }
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
  
      AuthService.logout();
      commit('logout');

    },



    

    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {


    
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      //remove db 
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};
