<template>
    <div class="scrollable-container" ref="scrollbableContainer">
        <div class="scrollable-items" ref="scrollableItems">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isDragging: false,
            startX: null,
            scrollLeft: null,
        };
    },
    mounted() {
        const scrollableItems = this.$refs.scrollableItems;
        const scrollbableContainer = this.$refs.scrollbableContainer;

        scrollbableContainer.addEventListener("touchstart", (e) => {
            this.isDragging = true;
            this.startX = e.touches[0].pageX - scrollbableContainer.offsetLeft;
            this.scrollLeft = scrollbableContainer.scrollLeft;
        });

        scrollbableContainer.addEventListener("touchmove", (e) => {
            if (!this.isDragging) return;
            //check if scrollbableContainer is at ending..
            const isAtEnding = scrollbableContainer.scrollLeft + scrollbableContainer.offsetWidth >= scrollableItems.offsetWidth;
            if (isAtEnding && e.touches[0].pageX - scrollbableContainer.offsetLeft < this.startX)
            {
                alert('aie');
            }
           
            return;
            const x = e.touches[0].pageX - scrollbableContainer.offsetLeft;
            const walk = (x - this.startX) * 2;
            scrollableItems.scrollLeft = this.scrollLeft - walk;
            scrollbableContainer.scrollLeft+=walk;
        });

        scrollbableContainer.addEventListener("touchend", () => {
            this.isDragging = false;
        });

        scrollbableContainer.addEventListener("touchcancel", () => {
            this.isDragging = false;
        });
    },
};
</script>

<style>
.scrollable-container {
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    /* permet de rendre le scroll plus fluide sur iOS */
}

.scrollable-items {
    display: inline-flex;
}
</style>
