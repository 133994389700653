<template>

    <Title title="Historique d'audit" />

    <div class="alert alert-dark">Vous devez avoir du réseau pour Télécharger un PDF</div>
<!-- Affiche une liste avec un lien pour télécharger le pdf, sous forme d'icone des audits -->
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4" v-for="audit in audits" :key="audit.id">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{audit.client.prenom}} {{audit.client.nom}}</h5>
                    <p class="card-text"> <!-- Date de l'audit au format français -->
                        <div class="d-flex justify-content-between">
                         <div class="">
                                {{formatDate(audit.date_created)}} 
                            </div>
                            <div class="">
                                <span v-if="audit.client.tel_mobile"><i class="fa fa-thin fa-phone"></i> {{audit.client.tel_mobile}}</span>
                                <span v-if="audit.client.tel_aidant"><i class="fa fa-thin fa-phone"></i> {{audit.client.tel_aidant}}</span>                            
                            </div>
                        </div>
                     </p>
                    <a  v-on:click="downloadAudit(audit.id)"  class="btn btn-primary" target="_blank"><i class="fa-light fa-file-pdf"></i></a>
                     
                    <a  v-on:click="downloadAudit(audit.id)"  class="btn btn-primary ml-2" target="_blank"><i class="fa-light fa-sheet-plastic"></i></a>

                </div>
            </div>
        </div>
    </div>
    

 </template>
    
<script>
import {post} from "../../store/post";
import config from "../../config";


export default {
    data() {
        return {
            audits: [],
            config:config,
        }
    },
    methods: {
        // Récupère la liste des audits
        getAudits() {
            post('audit/historique', {}, (response) => {
                this.audits = response.data;
            });
        },
        // Formate la date au format français
        formatDate(date) {
            return new Date(date).toLocaleDateString('fr-FR');
        },
        // Télécharge le pdf de l'audit
        downloadAudit(id)
        {
            this.UserService.downloadAudit(id);
        }
    

    },
    mounted() {
                this.audits = this.$store.state.dataPost.monObjet.audits;
    }
}
</script>
π