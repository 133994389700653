// import {
//   createStore
// } from "vuex";
// import {
//   auth
// } from "./auth.module";
// import {
//   Filesystem,
//   Directory,
//   Encoding
// } from '@capacitor/filesystem';
// import {
//   createToaster
// } from "@meforma/vue-toaster";
// import authHeader from '../services/auth-header';
// import axios from 'axios';
// import config from '../config';
// const API_URL = config.apiUrl + '/rha/api_connect/syncUpMaintenance/';

// const toaster = createToaster({
//   position: 'top'
// });

// const store = createStore({
//   modules: {
//     auth,
//     dataPost: {
//       state: {
//         init: false,
//         needSync: true,
//         //      const total = post.parc.length + post.maintenance.length + post.sav.length + post.arretLocations.length + post.installerLocation.length +post.audit.length;

//         monObjet: {
//           lastSyncDate: "Non synchronisé",
//           parc: [],
//           maintenance: [],
//           sav: [],
//           arretLocations: [],
//           installerLocation: [],
//           audit: [],
//         }
//       },
//       mutations: {
//        async setMonObjet(state, nouvelObjet) {
//           console.log("on set le monObjet", nouvelObjet);

//           state.monObjet = nouvelObjet;
//           if (state.needSync == true) {
//             state.needSync = false;
//             //this.dispatch('sync');
//           }
//           this.dispatch('sync');

//           try {
//             // Enregistrez automatiquement le changement dans le fichier post.json
//                   console.log("on essaie d'écrire le fichier post.json");

//             await Filesystem.writeFile({
//               path: 'post.json',
//               data: JSON.stringify(state.monObjet),
//               directory: Directory.Data,
//               encoding: Encoding.UTF8,
//             });
//             console.log("on a écrit le fichier post.json");
//             toaster.info("Données enregistrée  fichier post.json");
//           } catch (error) {
//             console.error('Erreur lors de l\'écriture du fichier :', error);
//             toaster.error("Erreur lors de l'écriture du fichier : " + error);
//           }

//         },
//       },
//       actions: {
//         async lireMonObjet({
//           commit,
//           state
//         }) {
//           console.log('First read');

//           state.init = true;
//           state.needSync = false;

//           try {

//             const file = await Filesystem.readFile({
//               path: 'post.json',
//               directory: Directory.Data,
//               encoding: Encoding.UTF8,
//             });
//             const objet = JSON.parse(file.data);
//             commit('setMonObjet', objet);
//             toaster.info("Données récupérées du fichier post.json");
//           } catch (error) {
//             console.error('Erreur lors de la lecture du fichier :', error);
//             toaster.error("Erreur lors de la lecture du fichier : " + error);
//           }

//         },

//         async sync() {
//           console.log('sync');

//           toaster.info("Synchronisation en cours ! Veuillez patienter...");
//           const data = {
//             post: store.state.dataPost.monObjet
//           };

//           console.log(authHeader());
//           let retour = axios.post(API_URL + 'sync_up', {
//             data
//           }, {
//             headers: authHeader()
//           }).then(
//             (response) => {
//               console.log(response);


//               if (response.data.error) {
//                 toaster.error(response.data.error);
//                 return false;
//               } else if (response.data.success) {
//                 toaster.success(response.data.success);
//               } else {
//                 toaster.error("Synchronisation impossible.  Reconnectez-vous.");
//                 return false;
//               }

//               // return;

//               //  store.commit("auth/setUserData", {
//               //       hexastat_parc: response.data.hexastat_parc,
//               //       hexastat_ligne: response.data.hexastat_ligne,
//               //       clients: response.data.clients,
//               //       audits: response.data.audits,
//               //       residents: response.data.residents,
//               //       droitAudit: response.data.droitAudit,
//               //       chat: response.data.chat,
//               //  });



//               // Enregistrement des données dans le système de stockage (Capacitor Filesystem)
//               const dataToSave = {
//                 parc: [],
//                 maintenance: [],
//                 sav: [],
//                 chat: [],
//                 arretLocations: [],
//                 installerLocation: [],
//                 client: [],
//                 audit: [],
//                 lastSyncDate: new Date().toLocaleString(),
//                 hexastat_parc: response.data.hexastat_parc,
//                 hexastat_maintenance: response.data.hexastat_maintenance,
//                 hexastat_ligne: response.data.hexastat_ligne,
//                 clients: response.data.clients,
//                 audits: response.data.audits,
//                 residents: response.data.residents,
//                 droitAudit: response.data.droitAudit,
//                 chat: response.data.chat,
//                 "test": "test",
//               };
//               this.state.dataPost.monObjet = dataToSave;
//               toaster.success("Synchronisation Réussie.");


//               //alert('on a commit les data to save');

//               // Sauvegardez les données dans le store Vuex pour "dataPost"
//               // store.dispatch("dataPost/saveDataPost", dataToSave);
//               //  // Enregistrez les données dans le store Vuex pour "auth" 
//               // store.dispatch("auth/update", store.state.dataPost.monObjet);

//               //store.dispatch("auth/update", store.state.dataPost.monObjet);



//             },
//             (error) => {
//               toaster.error("Synchronisation impossible. Vérifiez votre connexion internet.");
//               console.log(error);

//             });
//           return retour;



//         }



//       },
//       getters: {
//         getMonObjet(state) {
//           return state.monObjet;
//         },
//       },
//     },
//   },
// });

// store.subscribe((mutation, state) => {
//   // Store the state object as a JSON string
//   console.log(mutation, state);
//   // alert('MUTATION DONNE');
// });
// store.dispatch("lireMonObjet");
// export default store;







import { createStore } from "vuex";
import { auth } from "./auth.module";
import { createToaster } from "@meforma/vue-toaster";
import authHeader from '../services/auth-header';
import axios from 'axios';
import config from '../config';
import { openDB } from 'idb';

const API_URL = config.apiUrl + '/rha/api_connect/syncUpMaintenance/';
const toaster = createToaster({ position: 'top' });

// Initialiser IndexedDB
const dbPromise = openDB('myDatabase', 1, {
  upgrade(db) {
    db.createObjectStore('monObjetStore');
  },
});

const store = createStore({
  modules: {
    auth,
    dataPost: {
      state: {
        init: false,
        needSync: true,
        monObjet: {
          lastSyncDate: "Non synchronisé",
          parc: [],
          maintenance: [],
          config:{},
          hexastat_parc: [],
          sav: [],
          arretLocations: [],
          residents: [],
          config_general:{},
          produit: [],
          chat: [],
          hexastat_ligne: [],
          etablissement: [],
          installerLocation: [],
          audit: [],
        }
      },
      mutations: {
        async setMonObjet(state, nouvelObjet) {
          state.monObjet = nouvelObjet;
          if (state.needSync == true) {
            state.needSync = false;
          }
          //this.dispatch('sync');
          try {
            const db = await dbPromise;
            nouvelObjet= JSON.parse(JSON.stringify(nouvelObjet)); // 

            await db.put('monObjetStore', (nouvelObjet), 'monObjet');
            //toaster.info("Données enregistrée dans IndexedDB");
          } catch (error) {
            console.error('Erreur lors de l\'enregistrement dans IndexedDB :', error);
            toaster.error("Erreur lors de l'enregistrement dans IndexedDB : " + error);
          }
        },
      },
      actions: {
        async lireMonObjet({ commit }) {
                      //this.dispatch('clearMonObjet');

          console.log('Lecture depuis IndexedDB');
          try {
            const db = await dbPromise;
            const objet = await db.get('monObjetStore', 'monObjet');
            if (objet) {
              commit('setMonObjet', objet);
              //toaster.info("Données récupérées de IndexedDB");
              console.log('Données récupérées de IndexedDB');
            }
          } catch (error) {
            console.error('Erreur lors de la lecture de IndexedDB:', error);
            toaster.error("Erreur lors de la lecture de IndexedDB: " + error);
          }
        },
  async clearMonObjet(state) {
    try {
      const db = await dbPromise;
      await db.delete('monObjetStore', 'monObjet');
      state.monObjet = {}; // Réinitialiser l'état de monObjet
      toaster.info("Données supprimées de IndexedDB");
    } catch (error) {
      console.error('Erreur lors de la suppression dans IndexedDB :', error);
      toaster.error("Erreur lors de la suppression dans IndexedDB : " + error);
    }
  },

        async sync({ state }) {
          console.log('sync');
          
          toaster.info("Synchronisation en cours ! Veuillez patienter...");
          let data = {
            post: (state.monObjet)
          };
          //remove data.post.etablissement since we will not use it
          delete data.post.etablissement;
          delete data.post.hexastat_parc;
          delete data.post.residents;
          delete data.post.hexastat_ligne;
          delete data.post.hexastat_maintenance;

          //console.log(data);
          try {
            const response = await axios.post(API_URL + 'sync_up', { data }, { headers: authHeader() });
            console.log(response);

            if (response.data.error) {
              toaster.error(response.data.error);
              return false;
            } else if (response.data.success) {
              toaster.success(response.data.success);
            } else {
              toaster.error("Synchronisation impossible.  Reconnectez-vous.");
              return false;
            }

              const dataToSave = {
                parc: [],
                maintenance: [],
                sav: [],
                chat: [],
                arretLocations: [],
                installerLocation: [],
                client: [],
                config: (state.monObjet.config) ? state.monObjet.config : {},
                audit: [],
                lastSyncDate: new Date().toLocaleString(),
                hexastat_parc: response.data.hexastat_parc,
                hexastat_maintenance: response.data.hexastat_maintenance,
                config_general: response.data.config_general,
                hexastat_ligne: response.data.hexastat_ligne,
                clients: response.data.clients,
                produit: response.data.produit,
                etablissement: response.data.etablissement,
                audits: response.data.audits,
                residents: response.data.residents,
                droitAudit: response.data.droitAudit,
                chat: response.data.chat,
                "test": "test",
              };
              this.state.dataPost.monObjet = dataToSave;
              toaster.success("Synchronisation Réussie.");
          await this.commit('setMonObjet', dataToSave);
          toaster.success("Synchronisation réussie et données mises à jour dans IndexedDB.");

            // Traiter les données reçues et les enregistrer dans IndexedDB
            // Exemple : const dataToSave = { ... };
            // ... 
            // this.commit('setMonObjet', dataToSave);

          } catch (error) {
            toaster.error("Synchronisation impossible. Vérifiez votre connexion internet.");
            console.log(error);
          }
        }
      },
      getters: {
        getMonObjet(state) {
          return state.monObjet;
        },
      },
    },
  },
});

store.dispatch("lireMonObjet");
export default store;
export const post = store.state.dataPost.monObjet;
