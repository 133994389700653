<template>
  <Title title="Création Parc" />

  <div class="container">
    <header class="jumbotron">
      <h3>{{ content }}</h3>
    </header>
  </div>

  <small class="form-text text-muted">Assigner ce code à une ligne active d'un établissement</small>
  <button @click="assigne_choix('assigne')" class="btn btn-primary btn-block btn-dark">Sélectionner ligne dans
    établissement</button>

  <small class="form-text text-muted">Créer une ligne supplémentaire dans un établissement</small>
  <button @click="assigne_choix('add')" class="btn btn-primary btn-block btn-">Créer une ligne dans
    établissement</button>

  <br />



  <div id="assigne_assigne" v-if="assigneMethode=='assigne'">
    <fieldset class="border p-1 mb-2">
      <legend class='w-auto mb-0 h6'>Sélectionner ligne dans l'établissement</legend>
      <div class="form-group">
        <label for="selectionEts">Sélectionner un établissement</label>
        <select class="form-control" id="selectionEts" v-model="assignEts.etablissement"
          @change="saveConfigInPost('selectedEtablissementId', assignEts.etablissement);">
          <option disabled selected value> -- Sélectionner un établissement --</option>
          <option v-for="option in listeEts" :value="option.int_id_intervenant" :key="option.int_id_intervenant">
            {{ option.int_raisonsociale }}
          </option>
        </select>

        <br />

        <div class="search"> <input type="text" class="form-control" placeholder="Rechercher" v-model="searchQuery">
          <button class="btn btn-primary"><i class="fa-solid fa-magnifying-glass"></i></button>
        </div>

        <ul class="list-group" v-for="k in currentLigneEts" :key="k.hs_lp_id">
          <li class="list-group-item bg-secondary text-white">{{k.produit_designation}}</li>
          <li class="list-group-item">N° : #{{k.hs_lp_id}}</li>
          <li class="list-group-item">N° série : {{k.hs_lp_serie}}</li>
          <li class="list-group-item">N° Parc :{{k.hs_lp_code_parc}}</li>
          <li class="list-group-item">Chambre : {{k.chambre_lie_occupant}} {{k.lit_occupant}}</li>
          <li class="list-group-item"><button @click="attributionParc(k)" class="btn btn-primary btn-block"><i
                class="fa-solid fa-circle-check"></i> Attribuer à ce DM</button></li>

          <br />
        </ul>
        <br />

      </div>


    </fieldset>
  </div>


  <div id="assigne_add" v-if="assigneMethode=='add'">
    <fieldset class="border p-1 mb-2">
      <legend class='w-auto mb-0 h6'>Créer une ligne dans l'établissement: </legend>
      <br /><br />
      <div class="form-group">
        <label for="selectionEtsCrea">Sélectionner un établissement</label>
        <select class="form-control" id="selectionEtsCrea" v-model="crea.etablissement"
          @change="saveConfigInPost('selectedEtablissementId', crea.etablissement);">
          <option disabled selected value> -- Sélectionner un établissement --</option>
          <option v-for="option in listeEts" :value="option.int_id_intervenant" :key="option.int_id_intervenant">
            {{ option.int_raisonsociale }}
          </option>
        </select>
      </div>

      <div v-on:click="uploadPhotoAndRecognize()"><button class="btn btn-primary"><i class="fa fa-camera"></i> Tenter
          une reconnaissance de texte</button></div>
      <section v-if="crea.etablissement">
        <div v-if="reconnaissance_text">
          <div class="alert alert-info">Le texte sur la photo doit être lisible (à l'endroit). Vous pouvez sélectionner
            / coller le numéro de série.</div>
          <textarea class="form-control" id="reconnaissance_text" rows="5" v-model="reconnaissance_text"></textarea>
          <div id='text-img-container' style="max-width:150px"></div>
        </div>
        <div class="form-group">
          <label for="numeroSerie">Numéro de Série</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fa fa-barcode"></i>
              </div>
            </div>
            <input id="numeroSerie" name="numeroSerie" v-model="crea.hs_lpd_serie" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group">
          <label for="codeParc">Code Parc</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fa fa-barcode"></i>
              </div>
            </div>
            <input id="codeParc" name="codeParc" v-model="crea.hs_lpd_parc"
              placeholder="Votre numéro interne si vous l'avez" type="text" class="form-control">
          </div>
        </div>
        <div class="form-group">
          <label for="select">Produit</label>
          <!-- configFavoris-->
          <div v-if="configFavoris && configFavoris.length > 0" class="alert alert-info">
            <i class="fa-thin fa-star"></i>
            Vous avez une configuration de produits favoris. Seuls les produits favoris sont affichés.
            <u @click="saveConfigInPost('showAllProducts',true)" v-if="!showAllProducts">Les afficher tous
              temporairement</u>
            <u @click="saveConfigInPost('showAllProducts', false)" v-if="showAllProducts">N'afficher que mes favoris</u>


          </div>
          <div>
            <select id="select" name="select" class="custom-select" v-model="crea.hs_lpd_produit">
              <option v-for="option in produit" :value="option.produit_id" :key="option.produit_id">
                {{ option.produit_designation }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="select">Date de début de location</label>
          <div>
            <input id="date_debut_loc_crea" name="hs_lp_date_debut" v-model="crea.hs_lp_date_debut" type="date"
              class="form-control">
          </div>
        </div>

        <div class="form-group">
          <label for="select">Residents</label>
          <div>
            <select id="select" name="select" class="custom-select" v-model="crea.hs_lp_patient">
              <option v-for="option in residentsFiltered" :value="option.id" :key="option.id">
                {{ option.nom }} - {{ option.nom_service }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <!-- Statut-->
          <label for="hs_lp_statut">Statut</label>
          <select id="hs_lp_statut" name="hs_lp_statut" class="custom-select" v-model="crea.hs_lp_statut">
            <option value="1">Actif</option>
            <option value="11">Propriété établissement</option>
          </select>
        </div>



        <button class="btn btn-primary" @click="creaLigneParc(crea)"><i class='fa fa-plus'></i> Créer</button>
      </section>
    </fieldset>


  </div>

</template>

<script>
//import UserService from "../services/user.service";
import UserService from "../services/user.service";
import {post,savePost} from "../store/post";
import { createWorker, PSM, OEM } from 'tesseract.js';
import CameraScanner from "./sub/CameraScanner.vue";
const worker = createWorker({
  logger: m => console.log(m)
});
export default {
  name: "CreationParc",
  data() {
    return {
      content: "Création Parc",
      reconnaissance_text: "",
   
      assigneMethode:'',
      searchQuery:'',
   
      currentCode:''
      
    };
  },
  computed: {

    assignEts: {
      get() {
        console.log(this.config.selectedEtablissementId, 'selectedEtablissementId');
        return { etablissement: this.config.selectedEtablissementId };
      },
     
    },
    crea: {
      get() {
        return { etablissement: this.config.selectedEtablissementId, hs_lp_date_debut: new Date().toISOString().slice(0, 10) };
      },
     
    },

    showAllProducts: {
      get() {
        if (!this.config.showAllProducts) {
          return false;
        }
        return this.config.showAllProducts;
      },
      set(value) {
        this.saveConfigInPost('showAllProducts', value);
      }
    },

    config() {

      return this.$store.state.dataPost.monObjet.config;
    },
    listeEts() {
      let ets = this.$store.state.dataPost.monObjet.etablissement;
      ets.sort((a, b) => (a.int_raisonsociale > b.int_raisonsociale) ? 1 : -1);
      return ets;
    },
    configFavoris()
    {
      return (this.$store.state.dataPost.monObjet.config_general && this.$store.state.dataPost.monObjet.config_general.hs_ce_produits_favoris_maintenance) ? this.$store.state.dataPost.monObjet.config_general.hs_ce_produits_favoris_maintenance : [];
    },
    produit() {
      let products = this.$store.state.dataPost.monObjet.produit;

      //si on a une config de produits favoris
      let configFavoris = this.configFavoris;
      //configFavoris will be a list of produit_id separed by comma
      console.log(configFavoris,'les favoris');
      if (configFavoris.length > 0 && !this.showAllProducts) {
        let configFavorisArray = configFavoris.split(',');
        products = products.filter(function (k) {
          return configFavorisArray.includes(k.produit_id.toString());
        });
      }
      products.sort((a, b) => (a.produit_designation > b.produit_designation) ? 1 : -1);
      return products;

    },
    residents() {
      return this.$store.state.dataPost.monObjet.residents;
    },
    
  residentsFiltered()
  {
    if (!this.crea.etablissement) return [];
    console.log(this.residents);
    //filter residents by id_intervenant where id_intervenant=etablissement
    var x = this.residents.filter(resident => resident.id_intervenant == this.crea.etablissement)
    if (!x) { return []; }

    //sort by nom
    x.sort((a, b) => (a.nom > b.nom) ? 1 : -1);
    console.log(x);
    return x;
  },

   currentLigneEts()
   {
       const assignEts=this.assignEts;
       if (!assignEts.etablissement) {
           return [];
         }
        let hexastat_ligne= this.$store.state.dataPost.monObjet.hexastat_ligne.filter(function(k) {
            return k.hs_lp_int==assignEts.etablissement;
        });
     if (this.searchQuery) {
         hexastat_ligne= hexastat_ligne.filter(item => {
             if (item.produit_designation==null) { item.produit_designation='';}
                          if (item.chambre_lie_occupant==null) { item.chambre_lie_occupant='';}
                          if (item.lit_occupant==null) { item.lit_occupant='';}

          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.hs_lp_serie.toLowerCase().includes(v))
        ||
            this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.hs_lp_code_parc.toLowerCase().includes(v))
        ||
            this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.chambre_lie_occupant.toLowerCase().includes(v))
        ||
            this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.lit_occupant.toLowerCase().includes(v))
            ;

        });
     }
return hexastat_ligne;
   }

},
  methods: {

    saveConfigInPost(key, maintenance) {
      this.$store.state.dataPost.monObjet.config[key] = maintenance;
      this.$store.commit('setMonObjet', this.$store.state.dataPost.monObjet);
    },

   async uploadPhotoAndRecognize() {
 
      console.log('opening file photo reco');

      var fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*';
      var that = this;
      fileInput.onchange = function (e) {
          var file = e.target.files[0];
          var reader = new FileReader();
          reader.onload = async function(e) {
          var img = new Image();
            img.src = e.target.result;
            //wait img to be loaded
            await new Promise(resolve => img.onload = resolve);
        //resize the img to 512px and get the data url
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            const maxWidth = 512;
            const maxHeight = 512;
            if (img.width < maxWidth && img.height < maxHeight) {
              // Resize not required
              //('imageChange', {src:img.src,name:that.name,nameIndex:that.nameIndex});
              console.log('Inutile de resize');
              //return;
              var width = img.width;
              var height = img.height;
            }
            else {
              var ratio = Math.min(512 / img.width, 512 / img.height);
              var width = Math.round(img.width * ratio);
              var height = Math.round(img.height * ratio);
            }



            canvas.width = width;
            canvas.height = height;
            this.reconnaissance_text = "Traitement en cours...";
            var ctx = canvas.getContext('2d');
              await ctx.drawImage(img, 0, 0, width, height);
    
                var dataURL = canvas.toDataURL('image/png');
                console.log(dataURL);
                var img = document.createElement('img');
                img.id = 'text-img';
                img.src = dataURL;
                document.getElementById('text-img-container').innerHTML = '';
                document.getElementById('text-img-container').appendChild(img);   
            const textimg = document.getElementById('text-img');
                //add class to img img-responsive
            textimg.classList.add('img-thumbnail');
          
                //Si tu lis ça, il faudra faire mieux qu'un simple await d'une seconde.@todo
                await new Promise(resolve => setTimeout(resolve, 3000));
                //Reconnaissance du texte
                await worker.load();
                await worker.loadLanguage('fra');
                await worker.initialize('fra', OEM.LSTM_ONLY);
                await worker.setParameters({
                tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
                });
            const { data: { text } } = await worker.recognize(textimg);
            console.log("REco trouvé:" ,text);
           //that.crea.hs_lpd_serie=text;
            //this.crea.hs_lpd_serie = text;
            this.reconnaissance_text = text;
            return text;


          
     
        }.bind(this);
        reader.readAsDataURL(file);
      }.bind(this);
      fileInput.click();






    },

   

    async recognize(that) {
      const img = document.getElementById('text-img');
      console.log(img);
      alert('on reco');
      await worker.load();
      await worker.loadLanguage('fra');
      await worker.initialize('fra', OEM.LSTM_ONLY);
      await worker.setParameters({
        tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
      });
      const { data: { text } } = await worker.recognize(img);
      this.client.name = text;
      this.changeNom(text);
      return text;

    },


  
assigne_choix(choix) {
  this.assigneMethode=choix;
  //scrollto assigne_etablissement in pure-javascript
  console.log(choix);
  setTimeout(function(){ 
  var element = document.getElementById("assigne_" + choix);
  element.scrollIntoView({behavior: "smooth", block: "start"});
  },50);


  


},

creaLigneParc(k)
{
  //this.$toast.error(`Hey! I'm here`);
  if (!k.hs_lpd_produit) {
    this.$q.notify({
      message: 'Veuillez choisir un produit',
      color: 'negative',
      position: 'top',
      timeout: 2000
    });
    return;
  }
const randId = 'wait'+Math.random();
const randIdLigne = 'wait'+Math.random();
let tmp = k ;
tmp.hs_lpd_id=randId;
tmp.hs_lpd_date_attribution=new Date();
tmp.hs_lpd_serie=k.hs_lpd_serie;
  tmp.hs_lpd_parc = k.hs_lpd_parc;
tmp.new=1;
tmp.scan=this.currentCode;
tmp.hs_lpd_scan=this.currentCode;
  tmp.id_parc = k.randIdLigne;
  // find produit_designation in produit based on hs_lpd_produit
  tmp.produit_designation = this.produit.find(x => x.produit_id === k.hs_lpd_produit).produit_designation;

//find int_id_intervenant in listeEts where int_id_intervenant=k.etablissement
let etsTmp=this.listeEts.find(function(kg) {
 if (tmp.etablissement==kg.int_id_intervenant){ return kg ; }
});
tmp.hs_lp_int=tmp.etablissement;
tmp.int_raisonsociale=etsTmp.int_raisonsociale;
tmp.new_ligne = 1 ;



  let tmpLigne = k;
tmpLigne.hs_lp_id=randIdLigne;
tmpLigne.hs_lp_serie=k.hs_lpd_serie;
tmpLigne.hs_lp_code_parc=k.hs_lpd_parc;
tmpLigne.hs_lp_int=k.etablissement;
tmpLigne.hs_lp_produit=k.hs_lpd_produit;
  tmpLigne.hs_lp_date_attribution = new Date();
tmpLigne.new=1;
tmpLigne.hs_lp_scan=this.currentCode;
tmpLigne.hs_lp_current_parc=randId;

//post.parc.push(tmp) ; 

  this.$store.state.dataPost.monObjet.parc.push(tmp);
  this.$store.state.dataPost.monObjet.hexastat_parc.push(tmp);

  this.$store.state.dataPost.monObjet.installerLocation.push(tmpLigne);
  
  //savePost('parc', post.parc);

  //savePost();

  this.$router.push("/parc/" + randId + '/'+tmp.scan);


},
attributionParc(k)
{
const randId = 'wait'+Math.random();
  let tmp = k;

tmp.hs_lpd_id=randId;
tmp.hs_lpd_date_attribution=new Date();
tmp.hs_lpd_serie=k.hs_lp_serie;
tmp.hs_lpd_parc=k.hs_lp_code_parc;
tmp.new=1;
tmp.scan=this.currentCode;
tmp.hs_lpd_scan=this.currentCode;
tmp.id_parc=k.hs_lp_id;

this.$store.state.dataPost.monObjet.hexastat_parc.push(tmp);

      UserService.saveAttributionParc(tmp).then(
        (response) => {
          console.log(response);
        this.$router.push("/parc/"+randId + '/'+tmp.scan);
        
        },
        (error) => {
          console.log(error);
                  this.$router.push("/parc/"+randId + '/'+tmp.scan);

        });



}




},

  mounted() {
      this.currentCode = this.$route.params.code;
      this.content = "Création Parc ("+this.currentCode+")";
      console.log(this.$store.state.dataPost.monObjet.etablissement);

    
 



  },
};
</script>
