<template>
    <Title title="Rdv de maintenances" subtitle="Rdv enregistrés de maintenances à effectuer" :image="imageTitle" />

    <div>
        <div class="alert alert-info">
            Les maintenances déjà effectuées n'apparaîtront plus sur ce document
        </div>

        <!-- mesRdvParc case à cocher pour n'afficher que les rdv de mon utilisateur -->
        <div class="p-1 bd-highlight flex-fill d-flex align-items-center">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="mesRdvParc" v-model="mesRdvParc"
                    @change="saveConfigInPost('mesRdvParc', mesRdvParc)">
                <label class="form-check-label" for="mesRdvParc">Filtrer que les maintenances prévues pour {{
                    this.currentUser.login }}
                </label>
                <i class="help-icon fa-thin fa-question-circle" data-bs-toggle="tooltip" data-bs-placement="top"
                    @click="helpmesRdvParc()"></i>
            </div>
        </div>
        <!-- Si on a rien, on indique qu'on a pas de rdv enregistré pour le moment-->
        <div v-if="groupedItems.length === 0" class="alert alert-info">
            Aucun rdv de maintenance enregistré pour le moment
        </div>
        <div v-for="(item, index) in groupedItems" :key="index"
            :class="['rappel_maintenance_day', 'rappel_day_' + item.date]">
            <h2 :style="{ color: item.date < today ? 'red' : '' }">
                {{ formatDate(item.date) }}
                <span class="toggleDay" @click="toggleDay(index)">-</span>
            </h2>
            <div v-for="(rdv, rindex) in item.rdvs.slice(0, expandedItems[index] || maxShownPerDay) " :key="rindex"
                class="rappel_maintenance"> <a href="#" @click="movetoParc(rdv.hs_lpd_id)">
                    <div class="rappel_maintenance_title">
                        <div>{{ rdv.hs_lpd_id }}</div>
                        <div>{{ rdv.hs_lpd_scan }}</div>
                        <div>{{ rdv.produit_designation }}</div>
                    </div>
                </a>
                <div class="rappel_maintenance_localisation">
                    <div>{{ getLocalisation(rdv) }}</div>
                    <a :href="createNavigationGoogleMap(rdv) " v-if="rdv.nom" target="_blank" class="btn btn-light">
                        <i class="fas fa-map-marker-alt"></i> Voir sur Google Map
                    </a>

                </div>
                <div class="rappel_maintenance_rdv">
                    <div>{{ formatTime(rdv.hs_lpd_date_rdv) }}</div>
                    <div>{{ rdv.hs_lpd_rdv_utilisateur }}</div>
                </div>
            </div>

            <div v-if="item.rdvs.length > maxShownPerDay" class="showMore" @click="showMore(index)">
                Voir les {{ item.rdvs.length }} RDV
            </div>
        </div>
    </div>
</template>

<style scoped>
.rappel_maintenance_day {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    background-color: #f9f9f9;
}

.rappel_maintenance_day h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.rappel_maintenance_day h2 .toggleDay {
    cursor: pointer;
    font-size: 1.5em;
    font-weight: normal;
}

.rappel_maintenance {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    background: white;
}

.rappel_maintenance_title {
    font-weight: bold;
    font-size: 1.2em;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.rappel_maintenance_title div {
    margin-right: 10px;
}

.rappel_maintenance_rdv {
    display: flex;
    justify-content: space-between;
    font-size: 0.9em;
    margin-top: 5px;
}

.rappel_maintenance_rdv div {
    margin-right: 10px;
}

.showMore {
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    margin-top: 10px;
    text-decoration: underline;
}
</style>

<script>
import { format, startOfDay, isBefore } from 'date-fns';
import { fr } from 'date-fns/locale';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: 'top' });
import imageTitle from '@/assets/images/banniere_agenda.webp';
export default {

    data() {
        return {
            searchQuery: '',
            imageTitle: imageTitle,
            unFilteredCount: 0,
            urlProjet: '/url/du/projet',
            today: startOfDay(new Date()),
            maxShownPerDay: 1,
            expandedItems: {},
        }
    },

    name: 'Rdv parc planifiés',
    created() {
        this.$store.dispatch('lireMonObjet');
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;

            return this.$store.state.dataPost.monObjet; return this.$store.state.dataPost.monObjet;
        },
        config() {

            return this.$store.state.dataPost.monObjet.config;
        },
        mesRdvParc: {
            get() {
                if (!this.config.mesRdvParc) {
                    return false;
                }
                return this.config.mesRdvParc;
            },
            set(value) {
                this.saveConfigInPost('mesRdvParc', value);
            }
        },
        parc() {
            if (!this.$store.state.dataPost.monObjet.hexastat_parc) {
                return [];
            }
            //if mesRdvParc is checked, filter parc by hs_lpd_utilisateur = currentUser.login
            if (this.mesRdvParc) {
                return this.$store.state.dataPost.monObjet.hexastat_parc.filter(rdv => rdv.hs_lpd_rdv_utilisateur == this.currentUser.login);
            }
            return this.$store.state.dataPost.monObjet.hexastat_parc;
        },
        rsql() {
            //sort parc by hs_lpd_date_rdv. 
            let rdvs = [];
            rdvs = this.parc.sort((a, b) => new Date(a.hs_lpd_date_rdv) - new Date(b.hs_lpd_date_rdv));
            return rdvs;
        },
        groupedItems() {
            let items = this.parc;
            if (!items) {
                return [];
            }
            //remove all null date

            var tmp = items.reduce((acc, cur) => {
                // Convertissez la chaîne de date SQL en objet Date
                //check if date is NULL
                if (!cur.hs_lpd_date_rdv) return acc;
                let date = new Date(cur.hs_lpd_date_rdv.replace(/-/g, '/'));
                date = format(date, "yyyy-MM-dd", { locale: fr });
                if (!acc[date]) {
                    acc[date] = { date, rdvs: [] };
                }
                acc[date].rdvs.push(cur);
                return acc;
            }, {});
            return Object.values(tmp);
        },



    },

    methods: {
        saveConfigInPost(key, maintenance) {
            this.$store.state.dataPost.monObjet.config[key] = maintenance;
            this.$store.commit('setMonObjet', this.$store.state.dataPost.monObjet);
        },
        formatDate(dateString) {

            if (!dateString) return '';
            // Convertissez la chaîne de date SQL en objet Date
            let date = new Date(dateString.replace(/-/g, '/'));
            return format(date, "EEEE d MMMM y", { locale: fr });
        },
        formatTime(dateString) {
            if (!dateString) return '';
            // Convertissez la chaîne de date SQL en objet Date
            let date = new Date(dateString.replace(/-/g, '/'));
            return format(date, "HH:mm", { locale: fr });
          
        },
        movetoParc(id) {
            this.$router.push({ name: 'parcNoCode', params: { id: id } });
        },

        getLocalisation(rdv) {
            if (!rdv.nom) {
                return `${rdv.service_nom} - ${rdv.lit_occupant}`;
            }

            return `${rdv.nom} ${rdv.prenom} - ${rdv.ville} - ${rdv.adresse}`;
        },
        createNavigationGoogleMap(rdv) {
           

            return `https://www.google.com/maps/search/?api=1&query=${rdv.ville} - ${rdv.adresse}`;
        },
        toggleDay(index) {


            this.expandedItems[index] = (this.expandedItems[index] === 0) ? this.groupedItems[index].rdvs.length : 0;

        },

        showMore(index) {
            this.expandedItems[index] = this.groupedItems[index].rdvs.length;
        },
        helpmesRdvParc() {
            toaster.info("Permet de filtrer les maintenances à faire par l'utilisateur connecté");
        }
    }, 


    
}
</script>
     