<template>
  <div id="app">
    <div id="nav">
      
      <a class="btn btn-dark mt-1" style="cursor:pointer" :style="{'display': installBtn}" @click="installer()">
        <i class="fa-thin fa-mobile"></i> Installer l'application sur le téléphone
      </a>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      installBtn: "none",
      installer: undefined
    };
  },
  created() {
    let installPrompt;

    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      installPrompt = e;
      this.installBtn = "block";
    });

    this.installer = () => {
      this.installBtn = "none";
      installPrompt.prompt();
      installPrompt.userChoice.then(result => {
        if (result.outcome === "accepted") {
          console.log("Install accepted!")
        } else {
          console.log("Install denied!")
        }
      });
    };
  }
};
</script>
