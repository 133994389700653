import axios from 'axios';
import authHeader from './auth-header';
import { post,savePost } from '../store/post';
import { createToaster } from "@meforma/vue-toaster";
import config from '../config';
const toaster = createToaster({ position: 'top' });
import download from 'downloadjs'
import {useRoute} from 'vue-router'
import {computed} from 'vue'
import store from '../store';

//const API_URL = 'http://localhost/hexaplus/rha/api_connect/syncUpMaintenance/';
//const API_URL = 'http://192.168.100.177/hexaplus/rha/api_connect/syncUpMaintenance/';
//const API_URL = 'https://www.hexaplus.fr/rha/api_connect/syncUpMaintenance/';
const API_URL = config.apiUrl + '/rha/api_connect/syncUpMaintenance/';


class UserService {
setup(){

}



 async sync()
 {
   await store.dispatch('sync');
   //alert('dispatch sync');
   return false;
    const data = { post: post };
    toaster.info("Synchronisation en cours ! Veuillez patienter...");
    console.log(authHeader());
 let retour = axios.post(API_URL + 'sync_up',{data}, { headers: authHeader() }).then(
      (response) => {
     console.log(response);
     

     if (response.data.error)
     {
       toaster.error(response.data.error);
       return false;
      }
        else   if (response.data.success)
     {
        toaster.success(response.data.success);
     } 
     else {
       toaster.error("Synchronisation impossible.  Reconnectez-vous.");
       return false;
     }

    // return;

//  store.commit("auth/setUserData", {
//       hexastat_parc: response.data.hexastat_parc,
//       hexastat_ligne: response.data.hexastat_ligne,
//       clients: response.data.clients,
//       audits: response.data.audits,
//       residents: response.data.residents,
//       droitAudit: response.data.droitAudit,
//       chat: response.data.chat,
//  });
     
     
     
   // Enregistrement des données dans le système de stockage (Capacitor Filesystem)
    const dataToSave = {
      parc: [],
      maintenance: [],
      sav: [],
      chat: [],
      arretLocations: [],
      installerLocation: [],
      client: [],
      audit: [],
      hexastat_parc: response.data.hexastat_parc,
      hexastat_ligne: response.data.hexastat_ligne,
      clients: response.data.clients,
      audits: response.data.audits,
      residents: response.data.residents,
      droitAudit: response.data.droitAudit,
      chat: response.data.chat,
      "test": "test",
    };
     savePost(dataToSave);
     //alert('on commit les data to save');
     store.state.dataPost.monObjet.hexastat_parc = response.data.hexastat_parc;
     store.state.dataPost.monObjet.hexastat_ligne = response.data.hexastat_ligne;
     store.state.dataPost.monObjet.clients = response.data.clients;
     store.state.dataPost.monObjet.audits = response.data.audits;
     store.state.dataPost.monObjet.residents = response.data.residents;
     store.state.dataPost.monObjet.droitAudit = response.data.droitAudit;
     store.state.dataPost.monObjet.chat = response.data.chat;

     store.state.dataPost.monObjet.parc = [];
     store.state.dataPost.monObjet.maintenance = [];
     store.state.dataPost.monObjet.sav = [];
     store.state.dataPost.monObjet.chat = [];
     store.state.dataPost.monObjet.arretLocations = [];
     store.state.dataPost.monObjet.installerLocation = [];
     store.state.dataPost.monObjet.client = [];
     store.state.dataPost.monObjet.audit = [];
     store.state.dataPost.monObjet.parc = response.data.parc;
     
     
     //alert('on a commit les data to save');

    // Sauvegardez les données dans le store Vuex pour "dataPost"
    // store.dispatch("dataPost/saveDataPost", dataToSave);
    //  // Enregistrez les données dans le store Vuex pour "auth" 
    // store.dispatch("auth/update", store.state.dataPost.monObjet);

     //store.dispatch("auth/update", store.state.dataPost.monObjet);



        },
      (error) => {
        toaster.error("Synchronisation impossible. Vérifiez votre connexion internet.");
        console.log(error);
         
        });
    return retour;



  }
 
  saveAttributionParc(data) {
   store.dispatch("auth/update", store.state.dataPost.monObjet);

    let retour = axios.post(API_URL + 'save_maint', { headers: authHeader() }).then(
      (response) => {
        console.log(response);
        if (response.success)
        {
           toaster.success('Attribution parc enregistrée');
        }
        else {
          if (!post.parc)
          {
             post.parc = [];
           }
          post.parc.push(data);

              toaster.info(`Attribution parc enregistré en mode hors ligne.(` + post.parc.length + ")");
              localStorage.setItem('post', JSON.stringify(post));
        }
        },
      (error) => {
                      toaster.info(`Attribution parc enregistré en mode hors ligne.(` + post.parc.length + ")");
        console.log(error);
            post.parc.push(data);
        localStorage.setItem('post', JSON.stringify(post));


        });
    return retour;

  }

  saveMaintenance(data) {

        toaster.info(`Enregistré en mode hors ligne..(` + post.maintenance.length + ")");
        post.maintenance = [];
        post.maintenance.push(data);
    localStorage.setItem('post', JSON.stringify(post));
    return;


    let retour = axios.post(API_URL + 'save_maint', { headers: authHeader() }).then(
      (response) => {


        if (response.success) {
          toaster.success('Maintenance enregistrée');
        }
        else {
         
        toaster.info(`Enregistré en mode hors ligne..(` + post.maintenance.length + ")");
        post.maintenance = [];
        post.maintenance.push(data);
        localStorage.setItem('post', JSON.stringify(post));

        }
        },
      (error) => {
                      toaster.info(`Enregistré en mode hors ligne.(` + post.maintenance.length + ")");

        console.log(error);
        post.maintenance = [];
            post.maintenance.push(data);
        localStorage.setItem('post', JSON.stringify(post));


        });
    return retour;

  }

  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getParc() {
    
    return axios.get(API_URL + 'parc', { headers: authHeader() });
  }

  downloadAudit(id)
  {
    axios
        .get(`${config.apiUrl}/rha/api_connect/imprimer_audit/${id}/download`, {
            headers: authHeader(),
            responseType: 'blob', // had to add this one here
        })
        .then(response => {
           const content = response.headers['content-type'];
           download(response.data, "Audit", content)
        })
        .catch(error => console.log(error));
    




  }


}

export default new UserService();
