<template>
    <div class="containerFullscreen">
        <div class="buttonToggleRow">
            <button class="btn btn-primary m-1" @click="toggleFullscreen">Plein écran</button>
            <button class="btn btn-primary m-1" @click="$emit('zfullscreen-close')">Fermer</button>
            <button class="btn btn-primary m-1" @click="zoomIn"><i class="fa fa-thin fa-search-plus"></i></button>
            <button class="btn btn-primary m-1" @click="zoomOut"><i class="fa fa-thin fa-search-minus"></i></button>
              <div v-if="imageDescription" class="text-muted">{{ imageDescription }}</div>

        </div>
        <img ref="image" :src="imageUrl"  @dblclick="zoomOut"  class="imgFullScreen" :style="imageStyle" />
    </div>
</template>
<style scoped>
    .imgFullScreen {
        margin: auto;
        display: block;
        position: absolute;
        top: 55px;


    }
    .containerFullscreen {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background:white;
        z-index: 100;
        overflow-x:auto;
    }
    .buttonToggleRow {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
    }

</style>
<script>
export default {
    props: {
        imageUrl: {
            type: String,
            required: true,
        },
        imageDescription: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            isFullscreen: false,
            isMoving:false,
        };
    },
    computed: {
        imageStyle() {
            return this.isFullscreen ? {   } : {};
        },
    },
    mounted()
    {


    },
    methods: {
        toggleFullscreen() {
            if (!this.isFullscreen) {
                const elem = this.$refs.image;
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
                this.isFullscreen = true;
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
                this.isFullscreen = false;
            }
        },
        zoomIn() {
            const elem = this.$refs.image;
            elem.style.width = (elem.clientWidth + 100) + 'px';
        },
        zoomOut() {
            const elem = this.$refs.image;
            elem.style.width = (elem.clientWidth - 100) + 'px';
        },
    },
};
</script>
