<template>
  <div class="d-flex aligns-items-center aic justify-content-center vh-100">
  <div style="width:100%">
<h2 class="pt-4 mb-0">Connexion à Hexastat HL Mobilité</h2>
<div class="text-muted text-center">Veuillez saisir vos identifiants hexaplus</div>
  <div class="col-md-12">
    <div class="card card-container">
      <img
      style="display:none"
        id="profile-img"
        src="../assets/avatar.gif"
        class="profile-img-card hidden"
      />
            <img id="profile-img" src="../assets/images/logohexa.png" class="profile-img-logo" />
      <Form @submit="handleLogin" :validation-schema="schema">
        <div class="form-group form-group-login input-group mb-12">
          <label for="username"  class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"><i class="fa-thin fa-envelope"></i></span>
          </label>
          <Field id="username" name="username" type="text" class="form-control form-control-login" />
        </div>
                  <ErrorMessage name="username" class="error-feedback" />

        <div class="form-group form-group-login input-group mb-12">
          <label for="password" class="input-group-prepend">
            <span class="input-group-text"><i class="fa-thin fa-lock"></i></span>
          </label>
          <Field id="password" name="password" type="password" class="form-control form-control-login" />
        </div>
          <ErrorMessage name="password" class="error-feedback" />

        <div class="form-group">
          <button class="btn  btn-white btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Se connecter</span>
          </button>
        </div>
          <i>Version 0.92 (mars 2024)</i>
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
    </div>
  </div>
  <PWAPrompt></PWAPrompt>
  </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import PWAPrompt from './sub/PWAPrompt.vue';

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
    PWAPrompt
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Email (hexaplus) requis"),
      password: yup.string().required("Mot de passe requis!"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/home");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$store.state.auth.showBottomBar = true;
          this.loading = false;
          this.$router.push("/home");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};


</script>

<style scoped>
h2 { font-size:24px; text-align:center; font-weight:200;}
label {
margin:0;  
}

.card-container.card {
  max-width: 370px !important;
  padding: 10px 10px;
}

.card {
  background-color:transparent;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  border:none;

}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.profile-img-logo {
  height: 96px;
  width:auto;
  margin: 0 auto 10px;
  display: block;

}

.error-feedback {
  color: red;
}
.btn-white { background:white; border-radius:10px;}
.form-control-login {

  border:none;
  border-radius:0; background-color:transparent !important; border-bottom:solid 1px white; color:white;}
.form-group-login .input-group-text
{
  background-color:transparent !important;
  border-radius:0;
  border:none;
  border-bottom:1px solid white;
  color:white;
}
.form-control-login:focus {
  color:white;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
      -webkit-text-fill-color: white;
}

input[data-autocompleted] {
  background-color: transparent !important;
    -webkit-text-fill-color: white;
    color:white;
}
.align-items-center,.aic {
  align-items: center !important;
}
</style>
