<template>
<Title title="Gestion SAV" />

post:
{{ post }}

  <div class="form-group">
    <label for="text1">Sujet de l'intervention</label> 
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <i class="fa fa-database"></i>
        </div>
      </div> 
      <input v-model="sav.sujet" id="text1"  name="text1" type="text" class="form-control">
    </div>
  </div>
  <div class="form-group">
    <label for="text">Date</label> 

          <Datepicker locale="fr" inputClassName="form-control" cancelText="Annuler" selectText="Selectionner" :enableTimePicker="false" v-model="sav.date"></Datepicker>

  </div>


  <div class="form-group">
    <label for="depannage">Statut du dépannage</label> 
    <div>
      <select id="depannage" name="depannage" v-model="sav.statut" class="custom-select">
        <option value="En cours">En cours</option>
        <option value="Planifié">Planifié</option>
        <option value="Terminé">Terminé</option>
      </select>
    </div>
  </div>

  <div class="form-group" v-if="sav.statut && sav.statut=='planifie'">
    <label for="text">Date d'intervention prévue</label> 

          <Datepicker locale="fr" inputClassName="form-control" cancelText="Annuler" selectText="Selectionner" :enableTimePicker="false" v-model="sav.datePlanifie"></Datepicker>
  </div>


  <div class="form-group">
    <label for="Etat_apres_intervention">Etat après intervention</label> 
    <div>
      <select id="Etat_apres_intervention" name="Etat_apres_intervention" v-model="sav.etat" class="custom-select">
        <option value="Produit totalement  opérationnel">Produit totalement  opérationnel</option>
        <option value="Produit opérationnel, nécessitera une réparation">Produit opérationnel, nécessitera une réparation </option>
        <option value="Produit totalement Hors Service">Produit totalement Hors Service </option>
      </select>
    </div>
  </div>
  <div class="form-group">
    <label for="description">Description</label> 
    <textarea id="description" name="description" cols="40" rows="3" class="form-control"></textarea>
  </div> 


 <fieldset class="border p-1 mb-2">
    <legend class='w-auto mb-0'><label>Photo 1</label> </legend>
<image-upload-resizer-component
      @imageChange="imageUpload"
        name='photo1'
                :upload-if="true"

        :max-width="712"
        :max-height="712"
        accept="image/*"
        capture="camera"
        doNotResize="['gif', 'svg']"
    ></image-upload-resizer-component>
 </fieldset>

 <fieldset class="border p-1 mb-2" v-if="sav.photo1 || sav.photo2">
    <legend class='w-auto mb-0'><label>Photo 2 </label> </legend>
<image-upload-resizer-component
      @imageChange="imageUpload"
        name='photo2'
        :upload-if="true"
        :max-width="712"
        :max-height="712"
        accept="image/*"
        capture="camera"
        doNotResize="['gif', 'svg']"
    ></image-upload-resizer-component>
 </fieldset>

 <div class="form-group">
    <label>Signature ?</label> 
    <div>
      <div class="custom-controls-stacked">
        <div class="custom-control custom-checkbox">
          <input v-model="sav.signature" id="checkbox_0" type="checkbox" class="custom-control-input" value="Oui"> 
          <label for="checkbox_0" class="custom-control-label">Oui</label>
        </div>
      </div>
    </div>
  </div> 
 <VueSignaturePad v-if="sav.signature"  id="signature"  height='100px' ref="signaturePad" />
 <fieldset class="border p-1 mb-2" >
    <legend class='w-auto mb-0'><label>Envoi mail </label> </legend>
      <div class="custom-controls-stacked">
        <div class="custom-control custom-checkbox">
          <input v-model="sav.envoi_mail" id="envoi_mail" type="checkbox" class="custom-control-input" value="Oui"> 
          <label for="envoi_mail" class="custom-control-label">Oui</label>
        </div>
      </div>
  <div class="form-group" v-if="sav.envoi_mail">
    <label for="text1">Destinataires</label> 
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <i class="fa fa-envelope"></i>
        </div>
      </div> 
      <input placeholder="mail@mail.fr séparés par des ;" v-model="sav.mail"  type="text" class="form-control">
    </div>
  </div>
      
 </fieldset>


  <div class="form-group">
    <button name="submit" type="submit" class="btn btn-primary" @click="savSav(sav)">Enregistrer</button>
  </div>


</template>


<script>
import UserService from "../services/user.service";
import imageUploadResizerComponent from './sub/ImageUpload.vue';
import {post} from "../store";

import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: 'top' });



export default {
  
  components:{ imageUploadResizerComponent},
  
  methods: {

imageUpload(info) {
  
this.sav[info.name] =info.src;

},

    savSav(sav) {

      console.log(sav);
      if (this.sav.signature)
      {
              const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

              this.sav.signatureImg = data; 
      }
      post.sav.push(sav) ; 




      toaster.show('Sav enregistré');
      return;
      //redirect to home
      this.$router.push('/home/');

  
     
    },

 onFileChange(e,name) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0],name);
    },
    createImage(file,name) {
      var image = new Image();
      var reader = new FileReader();
      reader.onload = (e) => {
        console.log(e);
         this.sav[name]  = e.target.result;
      };
    reader.readAsDataURL(file);
   
    },
    removeImage: function (photo) {
      this.sav[photo] = '';
      document.getElementById(photo).value='';
    }

  },

  mounted() {
    console.log("Voici le post sav",post);
      var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
  
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var today = dd + '/' + mm + '/' + yyyy;
        console.log(this.sav);
        this.sav.idParc = this.$route.params.id;
        this.sav.date=today;
  },
  name: "Sav",

  data() {
    return {
        sav:{photo1:'',photo2:''},
      content: "",

        currentParc:[],
  currentFiche:[],
  dataMaint:{},
  dataJson:false,
  post:post,
    };
  },


}

</script>
<style>
#signature {
  border: solid 1px black;
  margin-bottom:5px;

}
</style>