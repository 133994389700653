import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import config from "./config";

import {post} from './store/post';

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import VueQrcodeReader from "vue3-qrcode-reader";
import "./assets/css/app.css";
import "./assets/css/fa.css";

import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: 'top' });

import UserService from "./services/user.service";
import './registerServiceWorker'
import VueSignaturePad from 'vue-signature-pad';

import Datepicker from '@vuepic/vue-datepicker';

//import 'vue3-date-time-picker/dist/main.css'
import '@vuepic/vue-datepicker/dist/main.css';
//import title component
import Title from './components/sub/Title.vue';
import 'vue-final-modal/style.css'
import { createVfm } from 'vue-final-modal'
//import sub search
//import Search from './components/sub/Search.vue';




import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
const vuetify = createVuetify({
  components,
icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
  },
},
  directives,
})

const vfm = createVfm()

let app = createApp(App);
app.config.globalProperties.config = config; // On injecte config.
app.config.globalProperties.Toaster = toaster; // On injecte config.
app.config.globalProperties.UserService = UserService; // On injecte config.
app.use(router)
  .use(store)
  .use(vuetify)
  .use(toaster, {
  // One of the options
  position: "top",
})
  .use(post)
  .use(config)
  .use(VueSignaturePad)
  .component('Title', Title)
  .use(vfm)
  .use(VueQrcodeReader)
  .component('Datepicker', Datepicker)
  //.component('Search', Search)
  //.component("font-awesome-icon", FontAwesomeIcon)
    .use(toaster)
  .mount("#app");
  
