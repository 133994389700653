
<template>
<Title title="Lignes de locations" />

<div class="form-check">
    <input class="form-check-input" type="checkbox" v-model="afficher.date" id="defaultCheck1">
    <label class="form-check-label" for="defaultCheck1">
        Afficher les dates de début de location
    </label>
</div>

<div class="form-check">
    <input class="form-check-input" type="checkbox" v-model="afficher.serie" id="affiche_serie">
    <label class="form-check-label" for="affiche_serie">
        Afficher les n° de série
    </label>
</div>
<div class="form-check">
    <input class="form-check-input" type="checkbox" v-model="afficher.id" id="affiche_id">
    <label class="form-check-label" for="affiche_id">
        Afficher les Codes chronos logiciel
    </label>
</div>


    <div id="assigne_assigne">
   <fieldset class="border p-1 mb-2" >
    <legend class='w-auto mb-0 h6'>Sélectionner un établissement</legend>
   <div class="form-group">
<select  class="form-control" id="selectionEts" v-model="assignEts.etablissement">
    <option disabled selected value> -- Sélectionner un établissement --</option>
  <option v-for="option in listeEts" :value="option.int_id_intervenant" :key="option.int_id_intervenant">
    {{ option.int_raisonsociale }}
  </option>
</select>

<br/>

 <div class="search">  <input type="text" class="form-control" placeholder="Rechercher" v-model="searchQuery"> <button class="btn btn-primary"><i class="fa-solid fa-magnifying-glass"></i></button> </div>

<ul class="list-group" v-for="k in currentLigneEts" :key="k.hs_lp_id"  >
  <li class="list-group-item bg-secondary text-white">{{k.produit_designation}}</li>
  <li class="list-group-item" v-if="afficher.id">N° : #{{k.hs_lp_id}}</li>
  <li class="list-group-item" v-if="afficher.serie">N° série : {{k.hs_lp_serie}}</li>
  <li class="list-group-item">N° Parc :{{k.hs_lp_code_parc}}</li>
    <li class="list-group-item">Statut :
        
<span v-if="k.hs_lp_statut==0" class="badge  bg-secondary text-light"><i class="fa-solid fa-hourglass"></i> En commande</span>
<span v-if="k.hs_lp_statut==1" class="badge  bg-secondary text-light"><i class='fa fa-check'></i> Actif</span>


    </li>

  <li class="list-group-item" v-if="afficher.date">Début loc : {{k.hs_lp_date_debut}}</li>
  <li class="list-group-item">Chambre : {{k.chambre_lie_occupant}} {{k.lit_occupant}}</li>

    <li class="list-group-item">
        
        <button @click="arreterLocation(k)" v-if="k.hs_lp_id > 0" class="btn btn-danger btn-block "><i class="fa-solid fa-stop"></i> Arrêter location</button>
                <button v-if="k.hs_lp_statut==0 "  @click="installerLocation(k)" class="btn btn-success btn-block "><i class="fa-solid fa-check-to-slot"></i> Installer location </button>

        
        </li>
  <span v-if="k.sync" class="badge  bg-secondary text-light"><i class='fa fa-cloud-upload'></i> En attente de synchronisation</span>

  <br/>

</ul>
<br/>

    </div>
        

</fieldset>
</div>
        
   
</template>

<script>
//import UserService from "../services/user.service";
import UserService from "../services/user.service";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: 'top' });
export default {
  methods: {

arreterLocation(k){
k.arretLocations=1;
k.sync=1;
const data = {hs_lp_id:k.hs_lp_id};
      this.post.arretLocations.push(data);

//savePost();
  toaster.show('Enregistré !');

  },
installerLocation(k){
k.installerLocation=1;
k.sync=1;
k.hs_lp_statut=1;
const data = {hs_lp_id:k.hs_lp_id};
post.installerLocation.push(data);
savePost();
  toaster.show('Enregistré');

  },



  },
  name: "LignesLocation",
  data() {
    return {
      content: "Création Parc",
      assignEts:{},
      crea:{},
      afficher:{},
      assigneMethode:'',
      searchQuery: '',
      post: this.$store.state.dataPost.monObjet,
      listeEts:this.$store.state.dataPost.monObjet.etablissement,
      produit:this.$store.state.dataPost.monObjet.produit,
      currentCode:''
      
    };
  },
computed: {

   currentLigneEts()
   {
       const assignEts=this.assignEts;
       if (!assignEts.etablissement) {
           return [];
         }
        let hexastat_ligne= this.$store.state.dataPost.monObjet.hexastat_ligne.filter(function(k) {
            return k.hs_lp_int==assignEts.etablissement;
        });
     if (this.searchQuery) {
         hexastat_ligne= hexastat_ligne.filter(item => {
             if (item.produit_designation==null) { item.produit_designation='';}
                          if (item.chambre_lie_occupant==null) { item.chambre_lie_occupant='';}
                          if (item.lit_occupant==null) { item.lit_occupant='';}

          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.hs_lp_serie.toLowerCase().includes(v))
        ||
            this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.hs_lp_code_parc.toLowerCase().includes(v))
        ||
            this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.chambre_lie_occupant.toLowerCase().includes(v))
        ||
            this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.lit_occupant.toLowerCase().includes(v))
            ;

        });
     }

return hexastat_ligne;
   }

},

}
</script>