
<template>
  <div id="app">

    <div class="background" :class="{ otherColor: isSignIn }">
      <div class="container">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
    <div style='height:50px'></div>
    <nav v-if="currentUser && showBottomNav" class="mobile-bottom-nav">

      <div class="top-level">
        <section class="section">
          <div class="section-child">
            <router-link to="/home">
              <i class="fa-thin fa-qrcode gi-1-3x"></i>
              <h6>Scanner</h6>
            </router-link>
          </div>

          <div class="section-child">
            <a @click.prevent="sync">
              <i class="fa-thin fa-cloud-arrow-up gi-1-3x"></i>
              <span v-if="totalSyncWait >0"
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-light">
                {{totalSyncWait}}
              </span>
              <h6>Synchro</h6>
            </a>
          </div>

          <div class="section-child">
            <router-link to="/parc-liste">
              <i class="fa-thin fa-list"></i>
              <h6>Parc</h6>
            </router-link>
            <!--A lot more content here-->
          </div>
          <div class="section-child">
            <router-link to="/parc-rdv">
              <i class="fa-thin fa-calendar"></i>
              <h6>Rdv parc</h6>
            </router-link>
            <!--A lot more content here-->
          </div>
          <div class="section-child">
            <router-link to="/ligneslocation">
              <i class="fa-thin fa-comment-medical"></i>
              <h6>Locs</h6>
            </router-link>
            <!--A lot more content here-->
          </div>
          <div class="section-child">
            <router-link to="/chat">
              <i class="fa-thin fa-comment-medical"></i>
              <h6>Chat</h6>
            </router-link>
            <!--A lot more content here-->
          </div>

          <div class="section-child" v-if="droitAudit">
            <router-link to="/audit">
              <i class="fa-thin fa-house-chimney-crack"></i>
              <h6>Audit</h6>
            </router-link>
            <!--A lot more content here-->
          </div>




        </section>

      </div>
    </nav>
  </div>

</template>
<style scoped>
.otherColor {
  background-color: #12325E; 
  color:white;
  height:100vh;
  
}
</style>
<script>

import {post} from './store/post';
import config from './config';
const hapticsImpactMedium = async () => {
  await Haptics.impact({ style: ImpactStyle.Medium });
};

export default {
  name: 'App',

  data() {
    return {
      post: post,
    }
  },

  mounted(){
  },
  computed: {
    isSignIn() {
      return this.$route.path === '/login';
    },
    showBottomNav() {
      console.log(this.$store.state.auth.showBottomBar);
      return this.$store.state.auth.showBottomBar;
      return false;

    },
    totalSyncWait() {
      let post = this.$store.state.dataPost.monObjet;
      if (!post || !post.parc || !post.maintenance ) {
        return 0;
      }
      console.log("Donc post total", post);
      const total = post.parc.length + post.maintenance.length + post.sav.length + post.arretLocations.length + post.installerLocation.length +post.audit.length;
      return total;
    },
    droitAudit()
    {
      console.log("Donc", (this.$store.state.dataPost.monObjet.droitAudit));
      return this.$store.state.dataPost.monObjet.droitAudit;
    },
    
    currentUser() {
      return this.$store.state.dataPost.monObjet;
    },
    showBoard() {
      if (this.currentUser) {
        return true;
      }
      return false;
    },

    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },

  },
  methods: {
    async sync()
    {
      var futurRoute = null;
      // if (Capacitor.isNativePlatform()) {
      //   hapticsImpactMedium();
      // } 

      console.log(this.$route.name);
     
      if ( ((this.$route.name) == "parc" || (this.$route.name) == "maintenance"  ) && this.$route.params.id.includes("wait"))
      {
        var currentScan = this.$route.params.code;
      }
      



     await this.UserService.sync();
      // var tempid = get current route
      if (currentScan)
      {

        var currentParc = this.$store.state.dataPost.monObjet.hexastat_parc.find(function (k) {
          return k.hs_lpd_scan == currentScan;
        }.bind(this));
        futurRoute = "/" + this.$route.name + "/" + currentParc.hs_lpd_id + '/' + currentParc.hs_lpd_scan;
        console.log(futurRoute);
        this.$router.push(futurRoute);
      }

      console.log(this.$route);

    },

    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>
