import axios from 'axios';
import config from '../config';

//const API_URL = 'http://localhost/hexaplus/rha/api_connect/connect/';
const API_URL  = config.apiUrl + '/rha/api_connect/connect/';
//const API_URL = 'http://192.168.100.177/hexaplus/rha/api_connect/connect/';
class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        login: user.username,
        pass: user.password
      })
      .then(response => {
        
        if (response.data.accessToken) {
          let dataToSave = {
            accessToken: response.data.accessToken,
            username: response.data.username,
            email: response.data.email,
            login:user.username,
            roles: response.data.roles
          };
          localStorage.setItem('user', JSON.stringify(dataToSave));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }


  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
