  <template>

    <nav style="left:0; position:absolute;z-index:2"
      class="navbar navbar-expand-lg   navbar-fill w-100 navbar-dark bg-light bg-dark bg-main">
      <router-link v-if="$store.state.auth.showBottomBar" to="/" class="navbar-brand">
        <i class="fa-light fa-qrcode"></i> Hexastat HL
      </router-link>

      <span v-if="!$store.state.auth.showBottomBar" v-on:click="showBottomNavChange(true)" class='navbar-brand '> <i
          class="fa-light fa-backward-step navbar-brand "></i>Revenir</span>


      <span class='navbar-brand '>{{title}}</span>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
        aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" @click.prevent="logOut">
              <i class="fa-solid fa-sign-out-alt"></i> Se déconnecter
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" href='https://www.hexaplus.fr'>
              <i class="fa-solid fa-link"></i> Hexaplus
            </a>
          </li>
        </ul>
        <span class="navbar-text" v-if="currentUser">
          {{currentUser.login}} {{currentUser.raisonsociale}}
        </span>
      </div>
    </nav>
    <nav style="left:0; position:fixed; display:none;"
      class="navbar navbar-fill w-100 navbar-expand navbar-dark bg-dark bg-main">
      <router-link to="/" class="navbar-brand">

        <i class="fa-solid fa-screwdriver-wrench"></i>UC
      </router-link>

      <div class="navbar-collapse collapse">
        <span class='navbar-brand '>{{title}}</span>


        <ul class="nav navbar-nav ">
          <li>
            <a class="nav-link" @click.prevent="logOut">
              <i class="fa-solid fa-sign-out-alt"></i>
            </a>
          </li>
        </ul>
      </div>

      <div v-if="!currentUser" class="navbar-nav ml-auto">

        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <i class="fas fa-sign-in-alt"></i> Connexion
          </router-link>
        </li>
      </div>

    </nav>

    <div class="container">
      <h3 class="text-center">{{ title }}</h3>

    </div>


    <div style='margin-bottom:40px'></div>
    <img v-if="image">

    <div v-if="image" style="position:relative">
      <img :src="image" class="img-fluid mx-auto block" style="display:block" alt="Responsive image">
      <div
        class="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center"
        style="background-color: rgba(0, 0, 0, 0.5); top:0">
        <h2 class="text-white text-center mb-3">{{ title }}</h2>
        <p class="text-white text-center">{{ subtitle }}</p>
      </div>
    </div>




  </template>

<script>
import { post, savePost, resetForm } from "../../store/post";
import config from '../../config';
export default {
  data () {
    return {
    }
  },
computed: {
  currentUser() {
    return this.$store.state.auth.user;
    },

  },

props: {
    title: {
    type: String || Number,
        required: false,
        default: ''
  },
  subtitle: {
    type: String || Number,
        required: false,
        default: ''
  },
  image: {
    type: String,
    required: false,
    default: ''
  }
    

},




  methods: {


    
    showBottomNavChange(val)
    {
      this.$store.state.auth.showBottomBar = val;

    },
    logOut() {
      alert('Vous êtes déconnecté');
      //resetForm();
      //(savePost());
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');

    }



}
}
</script>
