
<template>

    <Title title="Audit domicile" />
    <div class="list-group">
        <li style='display:none' v-on:click="client_choix='charge';this.$store.state.auth.showBottomBar = false" class="list-group-item list-group-item-action">
            <i class='fa-light fa-plus-circle' ></i> Créer un nouveau audit
        </li>
         <router-link style="display:none" to="/audit-historique"><li class="list-group-item list-group-item-action">
            <i class='fa-light fa-history'></i> Visualiser vos anciennes fiches
        </li>
        </router-link>
        <li class="list-group-item list-group-item-action" v-show="client_choix=='new'" v-on:click="client_choix='charge'"><i class="fa-light fa-person-circle-check"></i> Charger un
             client ancien</li>
                     <li class="list-group-item list-group-item-action" v-show="client_choix=='charge'" v-on:click="client_choix='new'"><i class="fa-light fa-person-circle-plus"></i> Créer
             client nouveau</li>

    </div>


    <!-- Bouton pour créer un nouveau client qui affiche le div client-->

  

    <br /><br /><br />
    <!-- Menu déroulant qui laisse le choix pour sélectionner un clien parmi les clients-->

    <div class="form-group" v-if="client_choix=='charge'">
        <label for="selectionClient">Sélectionner un client.</label>
        <select class="form-control" id="selectionClient" v-model="client">
            <option disabled selected value> -- Sélectionner un client --</option>
            <option  v-on:click="client_choix='new'" v-bind:value="{}"> -- Créer un nouveau client --</option>

            <option v-for="option in clients" :value="option" :key="option.id">
                {{ option.nom }} {{ option.prenom }}
            </option>
        </select>
    </div>


    <div class="card">
        <div class="card-header p-2">
            <h5 class="m-0">Fiche client <span v-if='client.id'>#{{client.id}}</span></h5>
        </div>
        <div class="card-body">
            <!-- formulaire nom, prénom, sexe, date de naissance avec le model particulier -->
            <form>

                <!-- Nom, Prénom avec des icones font awesome  -->
                <div class="form-group ">
                    <label for="nom">Nom</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-thin fa-user"></i></span>
                        </div>
                        <input type="text" class="form-control" id="nom" placeholder="Nom" v-model="client.nom">
                    </div>
                </div>



                <div class="form-group ">
                    <label for="prenom">Prénom</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-thin fa-user"></i></span>
                        </div>
                        <input type="text" class="form-control" id="prenom" placeholder="Prénom"
                            v-model="client.prenom">
                    </div>
                </div>


                <div class="form-group">
                    <label for="exampleInputEmail1">Sexe</label>

                    <select class="form-control" name="client_sexe" v-model="client.sexe">
                        <option value=''>Non précisé</option>

                        <option value='F'>Femme</option>
                        <option value='M'>Homme</option>

                    </select>

                </div>
                <!-- Date de naissance avec des icones font awesome  -->
                <div class="form-group">
                    <label for="exampleInputEmail1">Date de naissance</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-thin fa-calendar"></i></span>
                        </div>
                        <input type="date" class="form-control" id="exampleInputEmail1" v-model="client.dateNaissance"
                            aria-describedby="emailHelp" placeholder="Date de naissance">
                    </div>
                </div>
                <!-- Email et téléphone avec des icone font awesome  -->
                <div class="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i
                                    class="fa fa-thin fa-envelope"></i></span>
                        </div>
                        <input type="email" class="form-control" id="exampleInputEmail1" v-model="client.email"
                            aria-describedby="emailHelp" placeholder="Email">
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Téléphone</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-thin fa-phone"></i></span>
                        </div>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="client.tel_mobile"
                            aria-describedby="emailHelp" placeholder="Téléphone">
                    </div>
                </div>
                <!-- adresse avec un icone font awesome -->
                <div class="form-group">
                    <label for="exampleInputEmail1">Adresse</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i
                                    class="fa fa-thin fa-map-marker"></i></span>
                        </div>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="client.adresse"
                            aria-describedby="emailHelp" placeholder="Adresse">
                    </div>
                </div>
                <!-- code postal avec un icone font awesome -->
                <div class="form-group">
                    <label for="exampleInputEmail1">Code postal</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i
                                    class="fa fa-thin fa-map-marker"></i></span>
                        </div>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="client.code_postal"
                            aria-describedby="emailHelp" placeholder="Code postal">
                    </div>
                </div>
                <!-- ville avec un icone font awesome -->
                <div class="form-group">
                    <label for="exampleInputEmail1">Ville</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i
                                    class="fa fa-thin fa-map-marker"></i></span>
                        </div>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="client.ville"
                            aria-describedby="emailHelp" placeholder="Ville">
                    </div>
                </div>





                <!-- Deux inputs sur la même ligne avec le poids et la taille en cm -->
                <div class="input-group mb-1">
                    <input type="text" class="form-control" id="clientPoids" v-model="client.poids" @keyup="imc"
                        aria-describedby="emailHelp" placeholder="Poids">
                    <span class="input-group-text">kg</span>
                    <input type="text" class="form-control" id="clientTaille" v-model="client.taille" @keyup="imc"
                        aria-describedby="emailHelp" placeholder="Taille">
                    <span class="input-group-text">cm</span>
                </div>
                <!-- Barre de progression avec client.imc et en texte client.imcText avec des couleurs selon l'imc -->
                <div class="progress mb-2">
                    <div class="progress-bar" role="progressbar"
                        :style="{width: '100' + '%',backgroundColor: client.imcColor}" aria-valuenow="client.imc"
                        aria-valuemin="0" aria-valuemax="100">{{client.imcText}} ( {{client.imc}})</div>
                </div>
                <!-- Checkbox pour demander si la personne vit seule ou accompagnée -->
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"
                        v-model="client.vit_seul">
                    <label class="form-check-label" for="defaultCheck1">
                        Est-ce que la personne vit seul ?
                    </label>
                </div>

                <!-- Checkbox pour demander si la personne a un aidant -->
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" v-model="client.aidant">
                    <label class="form-check-label" for="defaultCheck2">
                        Est-ce que la personne a un aidant ?
                    </label>
                </div>
                <!-- Si la personne a un aidant, demander nom, prenom, email et téléphone avec des icones dans le formulaire -->
                <div v-if="client.aidant">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Nom de l'aidant</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"><i
                                        class="fa fa-thin fa-user"></i></span>
                            </div>
                            <input type="text" class="form-control" id="exampleInputEmail1" v-model="client.nom_aidant"
                                aria-describedby="emailHelp" placeholder="Nom">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Prénom de l'aidant</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"><i
                                        class="fa fa-thin fa-user"></i></span>
                            </div>
                            <input type="text" class="form-control" id="exampleInputEmail1"
                                v-model="client.prenom_aidant" aria-describedby="emailHelp" placeholder="Prénom">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email de l'aidant</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"><i
                                        class="fa fa-thin fa-envelope"></i></span>
                            </div>
                            <input type="email" class="form-control" id="exampleInputEmail1"
                                v-model="client.mail_aidant" aria-describedby="emailHelp" placeholder="Email">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Téléphone de l'aidant</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"><i
                                        class="fa fa-thin fa-phone"></i></span>
                            </div>
                            <input type="text" class="form-control" id="exampleInputEmail1" v-model="client.tel_aidant"
                                aria-describedby="emailHelp" placeholder="Téléphone">
                        </div>
                    </div>
                </div>

                <!-- Tour de taille en CM et Taille de vêtement -->
                <i class="text-muted" style="font-size:11px">Ces champs sont à votre discretions, ils aident à un
                    choix
                    plus fin d'éventuels
                    produits</i>
                <div class="input-group">
                    <input type="text" class="form-control" id="clientTourTaille" v-model="client.tourTaille"
                        aria-describedby="emailHelp" placeholder="Tour de taille">
                    <span class="input-group-text">CM</span>
                    <input type="text" class="form-control" id="clientTaille" v-model="client.tailleVetement"
                        aria-describedby="emailHelp" placeholder="Taille Vêtement">
                    <span class="input-group-text">(S,M,L,XL..)</span>
                </div>


                <br />




            </form>
        </div>
    </div>
      <!--
    <h5>Score de l'audit todo : à supprimer ?</h5>

    <div class="text-muted">Plus le score est élevé, plus il faudra une aide.</div>
    <div class="progress mt-1">
        <div class="progress-bar bg-success" role="progressbar"
            v-bind:class="{ 'bg-info': (audit.score > 20 && audit.score < 30) }"
            v-bind:style="{ width: '' + audit.score + '%' }" aria-valuenow="{{audit.score}}" aria-valuemin="0"
            aria-valuemax="100">{{audit.score}}</div>
    </div>
-->
            <!-- 2 radios : preferencePriorite (Court  / complet)-->

        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="preferencePriorite" :value=" false ">
          <label class="form-check-label" for="flexRadioDefault1">
            Faire un audit complet
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="preferencePriorite" :value=" 1 ">
          <label class="form-check-label" for="flexRadioDefault2">
            Faire un audit court
          </label>
        </div>

    <div class="mt-2"></div>


    <div class="card card-audit" v-for="k in auditFormulaire" :key="k.id">
        <div class='card-audit-header' v-bind:style="{ 'background-image': 'url(' + k.image + ')' }">
            <div class='card-audit-header-title'>
                <h3 class='card-audit-header-title-text'>
                    <span class='card-audit-header-title-text-text'>
                        {{k.name}}
                    </span>
                </h3>
            </div>
        </div>



        <div class="card-body">
            <span class="text-muted" v-if="preferencePriorite">Certaines questions sont masquées</span>
            <section class="card-audit-question" v-for="kq in k.questions" :key="kq.id">
                <transition  name="fade" appear>
                    <div class="card-audit-question-item  d-flex"
                        v-if="(!preferencePriorite || (preferencePriorite && preferencePriorite <= kq.priorite) ) && !kq.vif || (audit[k.id] && audit[k.id][kq.vif]  && audit[k.id][kq.vif].answer)">
                        <div class="card-audit-question-item-title mr-2">
                            <span class="card-audit-question-item-title-text">
                                {{kq.title}}
                            </span>
                            <div  v-if="kq.image " class="card-audit-question-item-image">
                                <img v-bind:src="kq.image" class="img-fluid rounded-top" alt="Responsive image">
                            </div>


                        </div>
                        
                        <div class="form-check p-1 " v-if="kq.help">
                            <i class="fa-question " v-bind:class="{
                                'fa-solid': (audit[k.id] && audit[k.id][kq.id] && audit[k.id][kq.id].help === true),
                                'fa-thin': !(audit[k.id] && audit[k.id][kq.id] && audit[k.id][kq.id].help === true)
                            
                            }" v-on:click="likeQuestion(k.id,kq,'help')"></i>
                        </div>
                        <div class="form-check p-1 "
                            v-bind:class="{ active: (audit[k.id] &&  audit[k.id][kq.id]&& audit[k.id][kq.id].answer) }">
                            <i class="fa-check " v-bind:class="{
                                'fa-solid': (audit[k.id] && audit[k.id][kq.id] && audit[k.id][kq.id].answer),
                                'fa-thin': !(audit[k.id] && audit[k.id][kq.id] && audit[k.id][kq.id].answer)
                            
                            }" v-on:click="likeQuestion(k.id,kq,true)"></i>
                        </div>
                        <div class="form-check p-1"
                            v-bind:class="{ active: (audit[k.id] && audit[k.id][kq.id] && audit[k.id][kq.id].answer===false) }">
                            <i class=" fa-xmark" v-bind:class="{
                                'fa-solid': (audit[k.id] && audit[k.id][kq.id] && audit[k.id][kq.id].answer === false),
                                'fa-thin': !(audit[k.id] && audit[k.id][kq.id] && audit[k.id][kq.id].answer === false)
                            }" v-on:click="likeQuestion(k.id,kq,false)"></i>
                        </div>

                    </div>

                </transition>
                






                <transition name="fade" appear>
                    <div class="card-audit-question-mesure mt-1"
                        v-if="(audit[k.id] && audit[k.id][kq.id] && audit[k.id][kq.id].help===true) ">
                        <div class="card-audit-question-mesure-title">
                            <div class="card-audit-help-img">
                                <h6>Photos démonstrations</h6>
                                <span class="card-audit-question-mesure-title-text text-muted">Ces images sont données à titre indicatives, pour support.</span>

                                <div  v-if=" !kq.help.image && !kq.help.no_image_auto && (audit[k.id] && audit[k.id][kq.id] && audit[k.id][kq.id].help === true) " class="card-audit-question-item-image d-flex">
                                    <HorizontalScroll>
                                        <div v-for="n in 20"  class="item">
                                        <img :id="'imgProd' + kq.id + n +'-loader' " style="width:200px" src="../../assets/images/spinner.gif">
                                        <img @click=" openImgInModal('imgProd'+kq.id+n)" @load="onImageLoad('imgProd'+kq.id+n)" :id="'imgProd'+kq.id+n"  :src="'https://www.hexaplus.fr/rh/api_tds/image/'+kq.id+'/'+n" style='width:200px;display:none' class="img-responsive rounded"/>
                                    
                                    </div>

                                </HorizontalScroll>
                                </div>
                                <div v-if="kq.help.no_image_auto "><img src="https://www.hexastat.fr/img/no_image.gif" class="img-responsive rounded"/></div>


                                <img v-if="kq.help.image" :src="kq.help.image" alt="" class="img-responsive rounded">
                                <img v-if=" kq.help.image2 " :src=" kq.help.image2 " alt="" class="img-responsive rounded">
                                <img v-if=" kq.help.image3 " :src=" kq.help.image3 " alt="" class="img-responsive rounded">

                            </div>
                            <span class="card-audit-question-mesure-title-text text-muted">
                                {{kq.help.description}}
                            </span>
                        </div>
                    </div>
                </transition>
                <div class="card-audit-question-mesure mt-1"
                    v-if="(audit[k.id] && audit[k.id][kq.id] && audit[k.id][kq.id].answer===true) ">

                    <div class="card-buttons d-flex justify-content-around mt-2">
                        <div class="card-button-item" v-on:click="infoProduitOrigine(k.id,kq,1)">
                            <div v-if="kq.type=='produit'"><i class="fa-house-user"
                                v-bind:class="{
                                'fa-solid': (audit[k.id][kq.id].infoProduitOrigine ==1),
                                'fa-thin': !(audit[k.id][kq.id].infoProduitOrigine ==1)
                                }"></i> <span style="font-size:11px">il en a déjà un</span></div>
                        </div>
                        <div class="card-button-item" v-on:click="infoProduitOrigine(k.id,kq,2)">
                            <div v-if="kq.type=='produit' && !kq.non_louable"><i class="fa-house-medical-flag"
                             v-bind:class="{
                             'fa-solid': (audit[k.id][kq.id].infoProduitOrigine ==2),
                             'fa-thin': !(audit[k.id][kq.id].infoProduitOrigine ==2)
                             }"></i>  <span style="font-size:11px">il en loue déjà</span></div>
                        </div>
                        <div class="card-button-item">
                            <i class=" fa-comment " v-bind:style="[kq.mesure ? {color:'lightblue'} : {}]" v-bind:class="{
                            'fa-solid': (audit[k.id][kq.id].commentShow),
                            'fa-thin': !(audit[k.id][kq.id].commentShow)
                            }" v-on:click="commentsQuestion(k.id,kq)"></i>
                        </div>
                        <div class="card-button-item">
                            <i class="fa-camera" v-bind:class="{
                            'fa-solid': (audit[k.id][kq.id].photos),
                            'fa-thin': !(audit[k.id][kq.id].photos)
                            }" v-on:click="upLoadPhotoQuestion(k.id,kq)"></i>
                        </div>



                    </div>
                    <!--Show Photo as img -->
                    <div class="card-audit-question-item-photo mt-2"
                        v-if="audit[k.id][kq.id].photos && audit[k.id][kq.id].photos.length > 0">

                        <div v-on:click="showPhotoQuestion(k.id,kq,p)" class="card-audit-question-item-photo-item"
                            v-for="p in audit[k.id][kq.id].photos" :key="p">

                            <!--add a cross button to delete image -->
                            <div class="card-audit-question-item-photo-item-img-delete">
                                <i class="fa-thin fa-times" v-on:click="deletePhotoQuestion(k.id,kq,p)"></i>
                            </div>
                            <img class="card-audit-question-item-photo-item-img" :src="p" alt="">

                        </div>
                    </div>
                                            <div v-if="kq.type=='produit'">
                                                <span v-if="audit[k.id][kq.id].infoProduitOrigine ==1" class="text-muted">Vous pouvez préciser si besoin le lieu d'achat du produit</span>
                                            </div>
                                            <div v-if="kq.type=='produit'">
                                                <span v-if="audit[k.id][kq.id].infoProduitOrigine ==2" class="text-muted">Vous pouvez préciser si besoin l'établissement louant le produit
                                                    </span>
                                            </div>
                    <transition name="fade" appear>

                        <div v-if="audit[k.id][kq.id].commentShow">
                            <ResizeTextarea :placeholder="kq.mesure" v-model="audit[k.id][kq.id].comment">
                            </ResizeTextarea>

                        </div>
                    </transition>
                </div>
            </section>












        </div>
    </div>
<div class="card mb-2">
    <div class="card-header p-2">
        <h5 class="m-0">Signature du client ou aidant</h5>
    </div>
    <div class="card-body">
        <div style="max-width:400px; border:solid 1px lightgray">
            <VueSignaturePad ref="signatureClient" />
        </div>
        
    </div>
</div>

<div class="card mb-2">
    <div class="card-header p-2">
        <h5 class="m-0">Signature du prestataire</h5>
    </div>
    <div class="card-body">
                <div style="max-width:400px; border:solid 1px lightgray">
                    <VueSignaturePad ref="signaturePrestataire" />
                </div>
    </div>
</div>


    <button class="btn btn-primary mb-4" v-on:click="saveAudit"><i class="fal fa-save"></i> Enregistrer
        l'audit</button>
    <br />
  
             <FullScreenImageViewer v-if="imageUrl" :image-url=" imageUrl " @zfullscreen-close="this.imageUrl=''" :image-description="'Image fournie à titre indicatif.'" />



</template>

<style>
.list-group-item.audit-item {
    background-position: 0;
    background-color:#db263a;
    min-height:150px;
    color:white;
}
.audit-item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: opacity .3s ease-in-out;
    opacity: .7;
}
.audit-chambre {
    background-"image":url('../../assets/images/audit/bed.svg');
    fill:red;
    background-size:400px; 
    background-repeat:no-repeat;
}
.card-buttons {
    color:rgb(112, 108, 108);
    font-size:24px;
        gap: 10px;
        display: flex;
        transition: all 0.3s ease-in-out;
          
}
.card-button-item:hoverz {
color:lightskyblue;

}
.card-img-bottom {
    width:100%;
    height:200px;
    object-fit: cover;
}
.card-buttons-2
{
    color:lightgrey;
    font-size:24px;
        gap: 10px;
}
.card-audit {
    min-height:150px;
    border-radius:10px !important;
    overflow: hidden;
    margin-bottom:10px;
}

.card-audit-header
{
    position: relative;
    margin-bottom: 0.5rem;
    padding-top: 10px;
    padding-bottom: 1rem;
    background-color: #6c757d;
    background-size: cover;
    background-attachment: scroll;
    height:450px;
    
}
.card-audit-header:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #212529;
    opacity: 0.5;
}
.card-audit-header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color:white;
    
}
.card-audit .card-body {
    padding: 0.75rem;
}
.card-button-item .clicked {
    color:rgb(59, 134, 182);
    transform:scale(1.05);

}
.card-audit-question {
    border-bottom:1px solid rgba(0, 0, 0, .125);
    margin-bottom:-1px;
}
.card-audit-question-item-title {
    width:80%;
}
.card-audit-question .form-check
{
    font-size:20px;
    margin-left:10px;
}

.card-audit-question .form-check.active {
    color:rgb(59, 134, 182);
}
.card-audit-question .form-check.active i {
    transform:scale(1.05);
}
.card-audit-question-item-photo-item
{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:10px;
    border-radius:5px;
    overflow:hidden;
    position:relative;
    border:solid 1px grey;
}
.card-audit-question-item-photo-item-img {
    width:100%;
    height:100%;
    object-fit: cover;
    max-width:200px;
}
.card-audit-question-item-photo-item-img-delete
{
    position: absolute;
    top: 5px;
    left: 0;
    right: 5px;
    bottom: 0;
    display: flex;
    justify-content: right;
    align-items: top;

}

.card-audit-question-item-image img {
    width:100%;
    height:100%;
    object-fit: cover;
    max-width:200px;
    margin-bottom:5px;
}

.card-audit-question-item-photo-item-img-delete i {
    font-size: 18px;
        background: rgb(56, 55, 55);
        color: white;
    padding:5px; 
    height:30px;
    width:30px;
    text-align: center;
    border-radius: 50%;
}
    
.card-audit-question-mesure-title-text 
{
    font-size:12px;
}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.s ease;
}

.fade-enter-from,
.fade-leave-to {
ty: 0;
}


</style>


<script>

import { post, savePost } from "../../store/post";
import { createWorker, PSM, OEM } from 'tesseract.js';
import ResizeTextarea from '../ResizeTextarea.vue';
import HorizontalScroll from '../HorizontalScroll.vue';
import FullScreenImageViewer from "../sub/FullScreenImageViewer.vue";
const worker = createWorker({
    logger: m => console.log(m)
});
export default {
  watch: {
  },
    components: {
        ResizeTextarea,
        HorizontalScroll,
        FullScreenImageViewer
    },
 
    methods: {
 
        async recognize(that)  {
            const img = document.getElementById('text-img');
            console.log(img);
            await worker.load();
            await worker.loadLanguage('fra');
            await worker.initialize('fra', OEM.LSTM_ONLY);
            await worker.setParameters({
                tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
            });
            const { data: { text } } = await worker.recognize(img);
            this.client.name = text;
            this.changeNom(text);
            return text;
           
        },
        onImageLoad: function (idImg) {
            console.log(idImg);
            document.getElementById(idImg).style.display = 'block';
            document.getElementById(idImg+'-loader').style.display = 'none';

        },
        //openImgInModal
        openImgInModal: function (idImg) {

            const src = document.getElementById(idImg).src;

            
            this.imageUrl = src;
            console.log(src);

        },
        listeAllAudit: function () {
            //show all questions id of auditFormulaire
            var liste = [];
            for (var i = 0; i < this.auditFormulaire.length; i++) {

                for(var j = 0; j < this.auditFormulaire[i].questions.length; j++)
                {
                    liste.push(this.auditFormulaire[i].questions[j].id);
                }
                
            }
            return liste;
            
        },
          

        onImageError: function (e) {
            console.log('error');
        },

        imc: function ()
        {
  
            console.log('calcul de imc...');
            //calcul imc of client
            var poids = this.client.poids;
            var taille = this.client.taille / 100;
            var imc = poids / (taille * taille);
      
            this.client.imcText = '';

            //if imc is not a number return false
            if (isNaN(imc)) {
                this.client.imcText = '';
                return '';
            }
            this.client.imc = imc.toFixed(2);

            if (imc < 16) {
                this.client.imcText = 'Anorexie';
                this.client.imcColor = 'red';
            }
            else if (imc >= 16 && imc < 18.5) {
                this.client.imcText = 'Maigreur';
                this.client.imcColor = 'orange';
            }
            else if (imc >= 18.5 && imc < 25) {
                this.client.imcText = 'Corpulence normale';
                this.client.imcColor = 'green';
            }
            else if (imc >= 25 && imc < 30) {
                this.client.imcText = 'Surpoids';
                this.client.imcColor = 'orange';
            }
            else if (imc >= 30 && imc < 35) {
                this.client.imcText = 'Obésité modérée';
                this.client.imcColor = 'orange';
            }
            else if (imc >= 35 && imc < 40) {
                this.client.imcText = 'Obésité sévère';
                this.client.imcColor = 'red';
            }
            else if (imc >= 40) {
                this.client.imcText = 'Obésité morbide';
                this.client.imcColor = 'red';
            }

            return '';

        },

        changeNom: function (text) {
            this.client.nom = text;
            let paragraph = text;
            paragraph= paragraph.replace('}', '');
            paragraph= paragraph.replace('{', '');
            console.log(paragraph);
            let tmp = '';
            let regex = /Nom\: (.*)/g;
            let found = paragraph.match(regex);
            if (found)
            {
                this.client.nom = found[0].replace('Nom: ', '');
            }
             regex = /Prénom(.*)/g;
             found = paragraph.match(regex);
            if (found)
            {
               //explode in array found on character :
                let tmp = found[0].split(':'); 
                this.client.prenom = tmp[1];
            }
            regex = /(Date of birth:|le: |le :)\s+(?:0[1-9]|[12][0-9]|3[01])[-/./,](?:0[1-9]|1[012])[-/./,](?:19\d{2}|20[01][0-9]|2020)/g
            //regex = /le \: (\d+).(.*)./g;
            found = paragraph.match(regex);
            if (found)
            {
                tmp = found[0].split(':');
                tmp = tmp[1];
                tmp = tmp.replaceAll(' ', '');
                tmp = tmp.replaceAll('.', '-');
                this.client.naissance = tmp;
            }
            else 
            {
                let regex = /le\: (.*)/g;

                //regex = /le \: (\d+).(.*)./g;
                found = paragraph.match(regex);
                if (found) {
                    tmp = found[0].split(':');
                    tmp = tmp[1];
                    tmp = tmp.replaceAll(' ', '');
                    tmp = tmp.replaceAll('.', '-');
                    tmp = tmp.replaceAll(',', '-');

                    this.client.naissance = tmp;
                }
            }



            regex = /(Sexe:|Sexe :) [A-Z]/g;
             found = regex.exec(text);
            if (found) {
                tmp = found[0].split(':');
                tmp = tmp[1];
                tmp = tmp.replaceAll(' ', '');
                //get first character
                this.client.sexe = tmp;
           
            }
            console.log(found);
        },

  


        like: function (post) {
            if (!this.audit[post.name]) {
                this.audit[post.name] = {
                    likes: 0,
                    dislikes: 0,
                    comments: 0,
                    photos: 0
                };
            }
            this.audit[post.name].like=true;
            console.log(this.audit);
     //   savePost(post);
        },


        likeQuestion: function (post, questionData, like) {
            const question = questionData.id;
            if (!this.audit[post]) {
                this.audit[post] = {
                    likes: 0,
                    dislikes: 0,
                };
            }
            if (!this.audit[post][question]) {
                this.audit[post][question] = {answer:''};
            }
    
            if (like===true) {
                this.audit[post][question].answer = true;
            }
            else if(like == 'help')
            {
                if (this.audit[post][question].help)
                {
                    this.audit[post][question].help = false;
                }
                else
                {
                    this.audit[post][question].help = true;
                }
                
            }
            else {
                this.audit[post][question].answer = false;
            }
            console.log(this.audit);
            this.calculScore();
        },




        
        commentsQuestion: function (post,questionData)
        {
            const question = questionData.id;
            if (this.audit[post][question].commentShow)
            {
                this.audit[post][question].commentShow = false;
            }
            else
            {
                this.audit[post][question].commentShow = true;
            }
        },
        upLoadPhotoQuestion: function (post,questionData)
        {
            const question = questionData.id;
            if (this.audit[post][question].photoShow)
            {
                this.audit[post][question].photoShow = false;
            }
            else
            {
                this.audit[post][question].photoShow = true;
            }
            if (!this.audit[post][question].photos)
            {
                this.audit[post][question].photos = [];
            }

            //create an input file and stock it for the camera photo show
            let input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.capture = 'camera';

            input.onchange = (e) => {
                let file = e.target.files[0];
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.audit[post][question].photos.push(e.target.result );
                };
                reader.readAsDataURL(file);
            };
            input.click();

        },
        deletePhotoQuestion: function (post,questionData,index)
        {
            const question = questionData.id;
            this.audit[post][question].photos.splice(index, 1);
            //delete photos if length <1 
            if (this.audit[post][question].photos.length < 1)
            {
                delete(this.audit[post][question].photos);
            }
        },
        showPhotoQuestion: function (post, questionData, photoData)
        {
          //create modal to show photo
            console.log(questionData);
            const question = questionData.id;
            let modal = document.createElement('div');
            modal.className = 'modal';
            modal.innerHTML = '<div class="modal-content">zaza<img src="' + photoData + '"/></div>';
            document.body.appendChild(modal);
            modal.addEventListener('click', () => {
                modal.remove();
            });
            console.log('show it');
        },
        infoProduitOrigine: function (post, questionData,value)
        {
            const question = questionData.id;
            if (this.audit[post][question].infoProduitOrigine && this.audit[post][question].infoProduitOrigine == value)
            {
                delete(this.audit[post][question].infoProduitOrigine);
            }
            else
            {
                this.audit[post][question].infoProduitOrigine = value;
                this.audit[post][question].commentShow = true;
            }
         
        },
        calculScore: function () {
            return 0;
            let score = 0;
            for (let post in this.audit) {
                for (let question in this.audit[post]) {
                    //find this.auditFormulaire[post][question]
                  //  console.log(this.auditFormulaire);
                    let formulaire = this.auditFormulaire.find(formulaire => formulaire.id == post);
                    let questionG = question;
                    if (formulaire && formulaire.questions)
                    {
                        formulaire = formulaire.questions.find(question => question.id == questionG);
                        if (this.audit[post][question].answer) {
                            score += formulaire.score;
                        }
                    }

                    //console.log(formulaire);
             
                }
            }
            this.audit.score = score;
        },
        saveAudit: function ()
        {

            var dodo = this.$refs.signatureClient.saveSignature();
            //let post = this.$store.state.dataPost.monObjet;
            let auditStore = this.$store.state.dataPost.monObjet.audit;
         
            this.audit.signatureClient = dodo.data; 

            let { isEmpty, data } = this.$refs.signaturePrestataire.saveSignature();
            this.audit.signaturePrestataire = data; 

            this.calculScore();
            console.log(this.audit);

            let audit = {
                client: this.client,
                audit: this.audit,
                score: this.audit.score
            };
            this.$store.state.dataPost.monObjet.audit.push(audit);
            
          
            this.$store.commit('setMonObjet', this.$store.state.dataPost.monObjet);

        },

        
        

    
    },
    mounted() {
        this.clients = this.$store.state.dataPost.monObjet.clients;
        this.audits = this.$store.state.dataPost.monObjet.audits;
  },
    data() {
        return {
            posts: [],
            imageUrl: '',
            showModal: false,
            audit: { "score": 40 },
            audits: {},
            client: {},
            clients: {},
            preferencePriorite: 0,
            client_choix: 'new',
            auditFormulaire: [
                {
                    "id": "loisirs",
                    "name": "Loisirs",
                    "image": "https://images.unsplash.com/photo-1448387473223-5c37445527e7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                    "questions": [
                        {
                            "id": "activite_physique_1",
                            "title": "Le domiciliant fait-il une activité physique tel que la marche à pied, la natation ou activité intérieur?"
                        },
                        {
                            "vif": "activite_physique_1",
                            "id": "equipement_bain",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'équipements de bain?",
                            "mesure": "Maillot ? Taille? Bonnet de bain ?",
                            "priorite": 3
                        },
                        {
                            "vif": "activite_physique_1",
                            "type": "produit",
                            "id": "activite_physique_3",
                            "title": "Le domiciliant a-t-il besoin d'un vélo d'appartement?",
                            "priorite": 2
                        },
                        {
                            "vif": "activite_physique_1",
                            "type": "produit",
                            "id": "activite_physique_4",
                            "title": "Le domiciliant a-t-il besoin d'un tapis de marche?",
                            "priorite": 3
                        }
                    ]
                },
                {
                    "id": "domicile",
                    "name": "Domicile",
                    "image": "https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1474&q=80",
                    "questions": [
                        {
                            "id": "appartement_maison_1",
                            "title": "Le domiciliant a-t-il besoin de Kit rampe?",
                            "mesure": "Combien de seuils? Quelle hauteur?",
                            "priorite": 3,
                            "help": {
                                "description": "Le Kit rampe est un ensemble de pièces qui permettent de créer une rampe d'accès à une entrée ou une porte. Il est très utile pour faciliter l'accès des personnes à mobilité réduite, notamment celles qui utilisent des fauteuils roulants ou des déambulateurs. La rampe peut être installée à l'extérieur ou à l'intérieur de la maison."
                            }
                        },
                        {
                            "id": "monte_escalier",
                            "type": "produit",
                            "title": "Le domiciliant a-t-il besoin d'un monte escalier?",
                            "priorite": 3,
                            "mesure": "escalier droit ou virage?",
                            "help": {
                                "description": "Le monte escalier est un siège motorisé qui monte ou descend un escalier. Il est très utile pour les personnes ayant des difficultés à monter les escaliers en raison d'un handicap ou de problèmes de santé. Le monte escalier peut être installé sur des escaliers droits ou courbes, à l'intérieur ou à l'extérieur de la maison."
                            }
                        },
                        {
                            "type": "produit",
                            "id": "ascenceur",
                            "title": "Le domiciliant a-t-il besoin d'un ascenseur?"
                        },
                        {
                            "type": "produit",
                            "id": "rampe",
                            "title": "Le domiciliant a-t-il besoin d'une rampe?",
                            "mesure": "Longueur? Hauteur?",
                            "help": {
                                "description": "La rampe est une structure métallique ou en bois qui peut être installée à l'intérieur ou à l'extérieur pour aider à surmonter des obstacles ou des escaliers. Elle est très utile pour les personnes à mobilité réduite, notamment celles qui utilisent des fauteuils roulants ou des déambulateurs. La rampe peut être fabriquée sur mesure pour s'adapter aux besoins spécifiques de chaque domicile."
                            }
                        }
                    ]
                },
                {
                    "id": "transfert",
                    "name": "Transfert",
                    "image": "https://www.hexaplus.fr/img/news/hexastat/audit/bannieres/transfert.jpg",
                    "questions": [
                        {
                            "type": "produit",
                            "id": "LEVE PATIENT",
                            "title": "Le domiciliant a-t-il besoin d'un lève patient?",
                            "mesure": "Taille de la sangle",
                            "help": {
                                "description": "Un lève patient est un équipement médical utilisé pour aider à déplacer un patient d'un lit ou d'une chaise à un autre, ou pour aider à le soulever de manière sécurisée. Il est souvent utilisé par les soignants à domicile pour aider les personnes ayant des problèmes de mobilité ou de force à se déplacer avec facilité et sécurité."
                            }
                        },
                        {
                            "type": "produit",
                            "id": "DISQUE DE TRANSFERT",
                            "title": "Le domiciliant a-t-il besoin d'un disque de transfert?",
                            "help": {
                                "description": "Un disque de transfert est un équipement médical utilisé pour faciliter le transfert d'un patient d'une position assise à une position debout ou vice versa. Il est souvent utilisé par les soignants à domicile pour aider les personnes ayant des problèmes de mobilité à se déplacer avec plus de facilité et de sécurité."
                            }
                        },
                        {
                            "type": "produit",
                            "id": "PLANCHE DE TRANSFERT",
                            "title": "Le domiciliant a-t-il besoin d'une planche de transfert?",
                            "help": {
                                "description": "Une planche de transfert est un équipement médical utilisé pour aider à transférer un patient d'un lit, d'une chaise ou d'un fauteuil roulant à un autre. Elle est souvent utilisée par les soignants à domicile pour aider les personnes ayant des problèmes de mobilité ou de force à se déplacer avec facilité et sécurité."
                            }
                        },
                        {
                            "type": "produit",
                            "id": "GUIDON DE TRANSFERT",
                            "priorite": 1,
                            "title": "Le domiciliant a-t-il besoin d'un guidon de transfert?",
                            "help": {
                                "description": "Un guidon de transfert est un équipement médical utilisé pour aider à transférer un patient d'un lit, d'une chaise ou d'un fauteuil roulant à un autre. Il est souvent utilisé par les soignants à domicile pour aider les personnes ayant des problèmes de mobilité ou de force à se déplacer avec facilité et sécurité."
                            }
                        },
                        {
                            "type": "produit",
                            "id": "RAIL AU PLAFOND",
                            "title": "Le domiciliant a-t-il besoin de rails au plafond?",
                            "help": {
                                "description": "Les rails au plafond sont un système de rail installé au plafond pour aider à déplacer un patient d'un lit, d'une chaise ou d'un fauteuil roulant à un autre en toute sécurité. Ils sont souvent utilisés par les soignants à domicile pour aider les personnes ayant des problèmes de mobilité ou de force à se déplacer avec facilité et sécurité."
                            }
                        }
                    ]
                },
                {
                    "id": "mobilite",
                    "name": "Mobilité",
                    "image": "https://www.hexaplus.fr/img/news/hexastat/audit/bannieres/mobilite.jpg",
                    "questions": [
                        {
                            "id": "CANNE",
                            "type": "produit",
                            "priorite": 1,
                            "title": "Le domiciliant a-t-il besoin d'une canne?",
                            "help": {
                                "description": "Une canne est un équipement médical utilisé pour aider à la marche. Elle est souvent utilisée pour aider les personnes ayant des problèmes de mobilité."
                            }
                        },
                        {
                            "id": "CANNE ANGLAISE",
                            "type": "produit",
                            "priorite": 1,
                            "title": "Le domiciliant a-t-il besoin d'une paire de canne anglaises?",
                            "help": {
                                "description": "Une canne anglaise, à la différence d'une canne classique, est équipée d'un appui sous l'avant-bras. Elle peut aider à soulager la douleur et à améliorer l'équilibre lors de la marche. Les cannes anglaises sont souvent utilisées en cas de douleurs chroniques, de faiblesse musculaire ou de troubles de l'équilibre."
                            }
                        },
                        {
                            "id": "DEAMBULATEUR",
                            "type": "produit",
                            "priorite": 1,
                            "title": "Le domiciliant a-t-il besoin d'un déambulateur?",
                            "mesure": "2 roues ? 3 roues ? 4 roues ?",
                            "help": {
                                "description": "Le déambulateur est un équipement qui se compose d'un cadre métallique et de poignées, et qui peut être équipé de roues ou de patins. Il permet de se déplacer plus facilement en offrant un soutien supplémentaire lors de la marche. Les déambulateurs sont souvent utilisés en cas de faiblesse musculaire, de troubles de l'équilibre ou de rééducation après une intervention chirurgicale."
                            }
                        },
                        {
                            "id": "FAUTEUIL_ROULANT",
                            "type": "produit",
                            "priorite": 1,
                            "title": "Le domiciliant a-t-il besoin d'un fauteuil roulant?",
                            "mesure": "Type de fauteuil roulant: manuel ou électrique",
                            "help": {
                                "description": "Un fauteuil roulant est un dispositif médical conçu pour aider les personnes à mobilité réduite à se déplacer. Les fauteuils roulants manuels nécessitent que l'utilisateur fasse tourner les roues à la main pour se déplacer, tandis que les fauteuils roulants électriques sont motorisés et peuvent être contrôlés à l'aide d'un joystick. Les fauteuils roulants peuvent être utilisés aussi bien à l'intérieur qu'à l'extérieur et sont souvent prescrits pour les personnes ayant une incapacité permanente ou temporaire."
                            }
                        },
                        {
                            "id": "FAUTEUIL_TRANSFERT",
                            "type": "produit",
                            "priorite": 1,
                            "title": "Le domiciliant a-t-il besoin d'un fauteuil de transfert?",
                            "mesure": "Taille du fauteuil de transfert: petit, moyen ou grand",
                            "help": {
                                "description": "Un fauteuil de transfert est un type de fauteuil roulant conçu pour faciliter les transferts entre un lit, une chaise ou une autre surface. Ils sont souvent utilisés dans les hôpitaux, les maisons de soins infirmiers ou les maisons privées pour les personnes ayant une mobilité réduite ou qui ont besoin d'une aide pour se lever ou s'asseoir."
                            }
                        },
                        {
                            "id": "SCOOTER_ELECTRIQUE",
                            "type": "produit",
                            "title": "Le domiciliant a-t-il besoin d'un scooter électrique?",
                            "mesure": "Portée du scooter électrique: nombre de kilomètres (intérieur? Extérieur?)",
                            "help": {
                                "description": "Un scooter électrique est un véhicule à moteur électrique qui peut être utilisé pour se déplacer à l'intérieur et à l'extérieur. Les scooters électriques sont conçus pour les personnes ayant une mobilité réduite et qui ont besoin d'une aide pour se déplacer sur de longues distances. Les scooters électriques sont souvent équipés de caractéristiques de sécurité telles que des feux de signalisation et des freins."
                            }
                        },
                        {
                            "id": "TRICYCLE",
                            "type": "produit",
                            "title": "Le domiciliant a-t-il besoin d'un tricycle?",
                            "mesure": "Type de tricycle: pour enfant ou pour adulte",
                            "help": {
                                "description": "Un tricycle est un véhicule à trois roues qui peut être utilisé comme alternative à un vélo pour les personnes ayant des problèmes d'équilibre ou de coordination. Les tricycles sont disponibles en différentes tailles et modèles, y compris pour les enfants et les adultes. Les tricycles peuvent être utilisés pour les loisirs ou comme moyen de transport pratique pour se déplacer sur de courtes distances."
                            }
                        }
                    ]
                },
                {
                    "id": "sejour",
                    "name": "Séjour",
                    "image": "https://www.hexaplus.fr/img/news/hexastat/audit/bannieres/sejour.jpg",
                    "questions": [
                        {
                            "id": "CHAUS_THERAPEUTIQUE",
                            "type": "produit",
                            "title": "Le domiciliant a-t-il besoin de chaussures thérapeutiques?",
                            "mesure": "Pointure des chaussures thérapeutiques: X, Y ou Z",
                            "help": {
                                "description": "Les chaussures thérapeutiques sont des chaussures spécialement conçues pour aider à traiter ou à prévenir les blessures ou les douleurs aux pieds. Ils peuvent être prescrits pour les personnes ayant des problèmes de circulation sanguine, de diabète, d'arthrite ou d'autres problèmes médicaux affectant les pieds. Les chaussures thérapeutiques sont souvent plus larges et plus profondes que les chaussures normales, et elles peuvent être personnalisées pour répondre aux besoins spécifiques de chaque patient."
                            }
                        },
                        {
                            "id": "DISQUE_TRANSFERT",
                            "type": "produit",
                            "priorite": 1,
                            "title": "Le domiciliant a-t-il besoin d'un disque de transfert?",
                            "mesure": "Diamètre du disque de transfert: X centimètres",
                            "help": {
                                "description": "Un disque de transfert est un disque plat et tournant conçu pour aider les personnes ayant des problèmes de mobilité à se déplacer plus facilement. Les disques de transfert peuvent être utilisés pour transférer une personne d'une chaise à un lit, ou pour entrer ou sortir d'une voiture. Les disques de transfert sont souvent légers et portables, et ils peuvent être utilisés avec ou sans l'aide d'une autre personne."
                            }
                        },
                        {
                            "id": "TABLE_LIT",
                            "type": "produit",
                            "title": "Le domiciliant a-t-il besoin d'une table de lit ou de fauteuil ou à manger?",
                            "mesure": "Taille de la table de lit ou de fauteuil ou à manger: petite, moyenne ou grande",
                            "help": {
                                "description": "Une table de lit ou de fauteuil ou à manger est un type de table portable conçue pour être utilisée par des personnes ayant une mobilité réduite ou qui sont confinées à leur lit. Les tables de lit ou de fauteuil ou à manger sont souvent réglables en hauteur pour s'adapter à différents types de chaises, de lits ou de fauteuils, et elles peuvent être utilisées pour manger, travailler ou lire confortablement."
                            }
                        },
                        {
                            "id": "FAUTEUIL_RELEVEUR",
                            "type": "produit",
                            "title": "Le domiciliant a-t-il besoin d'un fauteuil releveur?",
                            "mesure": "Taille du fauteuil releveur: petit, moyen ou grand",
                            "help": {
                                "description": "Un fauteuil releveur est un type de fauteuil électrique conçu pour aider les personnes ayant des problèmes de mobilité à se lever ou à s'asseoir plus facilement. Les fauteuils releveurs sont souvent équipés d'un mécanisme de levage qui soulève le siège et l'utilisateur"
                            }
                        },
                        {
                            "id": "PINCE_PREHENSION",
                            "title": "Le domiciliant a-t-il besoin d'une pince de préhension?",
                            "help": {
                                "description": "Une pince de préhension est un outil conçu pour aider les personnes ayant des problèmes de mobilité à saisir et à soulever des objets. Les pinces de préhension sont souvent utilisées pour ramasser des objets du sol ou d'une étagère, ou pour atteindre des objets qui sont hors de portée."
                            }
                        },
                        {
                            "id": "CHAUFFE_PIED",
                            "title": "Le domiciliant a-t-il besoin d'un chauffe-pied?",
                            "help": {
                                "description": "Un chauffe-pied est un appareil électrique conçu pour réchauffer les pieds. Les chauffe-pieds sont souvent utilisés par les personnes ayant des problèmes de circulation sanguine ou de diabète, ou par les personnes qui ont froid aux pieds."
                            }
                        },
                        {
                            "id": "COUVERTURE_CHAUFFANTE",
                            "title": "Le domiciliant a-t-il besoin d'une couverture chauffante?",
                            "help": {
                                "description": "Une couverture chauffante est un type de couverture électrique conçue pour réchauffer le lit ou le corps d'une personne. Les couvertures chauffantes sont souvent utilisées par les personnes ayant des problèmes de circulation sanguine ou de diabète, ou par les personnes qui ont froid au lit/canapé."
                            }
                        },
                        {
                            "id": "COUSSIN_VIBRANT_CHAUFFANT",
                            "title": "Le domiciliant a-t-il besoin d'un coussin vibrant et chauffant?",
                            "help": {
                                "description": "Le coussin vibrant et chauffant est un accessoire médical qui peut être utilisé pour aider à soulager la douleur et la tension musculaire. Il peut être particulièrement utile pour les personnes âgées, handicapées ou malades qui reçoivent des soins à domicile. Le coussin fonctionne en émettant des vibrations apaisantes qui aident à détendre les muscles tendus et à réduire la douleur. Il est également équipé d'un système de chauffage qui peut aider à améliorer la circulation sanguine et à soulager les douleurs articulaires. En somme, le coussin vibrant et chauffant est un outil précieux pour améliorer le confort et le bien-être des personnes qui souffrent de douleurs chroniques ou de tensions musculaires.."
                            }
                        },
                        {
                            "id": "TELEPHONE_ADAPTE",
                            "title": "Le domiciliant a-t-il besoin d'un téléphone adapté?",
                            "help": {
                                "description": "Un téléphone adapté est un téléphone conçu pour être utilisé par des personnes ayant des problèmes de mobilité ou de vision. Les téléphones adaptés sont souvent équipés de grandes touches et d'un écran facile à lire, et ils peuvent être utilisés pour passer des appels téléphoniques ou envoyer des SMS."
                            }
                        }
                    ]
                },
                {
                    "id": "chambre",
                    "name": "Chambre",
                    "image": "https://images.unsplash.com/photo-1505693416388-ac5ce068fe85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
                    "questions": [
                        {
                            "id": "LIT_MEDICALISE",
                            "type": "produit",
                            "priorite": 1,
                            "title": "Le domiciliant a-t-il besoin d'un lit médicalisé?",
                            "help": {
                                "description": "Un lit médicalisé est un lit spécialement conçu pour les personnes ayant des besoins médicaux particuliers, tels que les personnes âgées, handicapées ou malades. Il peut être équipé de diverses fonctionnalités telles qu'un relève buste, un relève jambe, une barre d'appui, etc."
                            }
                        },
                        {
                            "id": "MATELAS",
                            "type": "produit",
                            "priorite": 1,
                            "title": "Le domiciliant a-t-il besoin d'un matelas?",
                            "help": {
                                "description": "Un matelas peut être nécessaire pour améliorer le confort du domiciliant et réduire la douleur associée à des problèmes de dos ou d'articulations."
                            }
                        },
                        {
                            "id": "FAUTEUIL_REPOS",
                            "type": "produit",
                            "priorite": 1,
                            "title": "Le domiciliant a-t-il besoin d'un fauteuil de repos?",
                            "help": {
                                "description": "Un fauteuil de repos peut être utile pour permettre au domiciliant de s'asseoir confortablement et de se détendre."
                            }
                        },
                        {
                            "id": "TABLE_LIT",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une table de lit?",
                            "help": {
                                "description": "Une table de lit peut être utile pour permettre au domiciliant de manger, lire ou travailler depuis son lit."
                            }
                        },
                        {
                            "id": "DRAP_GLISSE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un drap de glisse?",
                            "help": {
                                "description": "Un drap de glisse est utilisé pour faciliter les mouvements et réduire les frottements lors des changements de position du domiciliant. Il peut être particulièrement utile pour les personnes souffrant de douleurs chroniques ou de mobilité réduite."
                            }
                        },
                        {
                            "id": "BOUILLOTTE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une bouillotte?",
                            "help": {
                                "description": "Une bouillotte peut être utile pour soulager les douleurs musculaires ou articulaires en appliquant de la chaleur sur la zone affectée."
                            }
                        },
                        {
                            "id": "ARCEAU_LIT",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un arceau de lit?",
                            "help": {
                                "description": "Un arceau de lit est utilisé pour aider le domiciliant à se lever et à se coucher plus facilement. Il peut également offrir un support supplémentaire pour les personnes ayant des problèmes de mobilité ou de stabilité."
                            }
                        },
                        {
                            "id": "BARRE_REDRESSEMENT_LIT",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une barre de redressement au lit?",
                            "help": {
                                "description": "Une barre de redressement au lit peut aider le domiciliant à se redresser et à s'asseoir plus facilement dans son lit. Elle peut également offrir un support supplémentaire pour les personnes ayant des problèmes de mobilité ou de stabilité"
                            }
                        },
                        {
                            "id": "ACCESSOIRES_TOILETTE_LIT",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'accessoires de toilette au lit?",
                            "mesure": "Bassin de lit? Urinal?",
                            "help": {
                                "description": "Des accessoires de toilette au lit peuvent être utiles pour aider le domiciliant à effectuer des tâches d'hygiène personnelle depuis son lit, tels qu'un bassin de lit, des lingettes humides ou un urinal de lit."
                            }
                        },
                        {
                            "id": "ENFILE_BAS",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un enfile bas?",
                            "help": {
                                "description": "Un enfile bas peut aider le domiciliant à enfiler ses chaussettes ou ses bas plus facilement, en particulier s'il a des difficultés à se baisser ou à atteindre ses pieds."
                            }
                        },
                        {
                            "id": "CHAUSSE_PIED",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un chausse-pied?",
                            "help": {
                                "description": "Un chausse-pied peut aider le domiciliant à enfiler ses chaussures plus facilement, en particulier s'il a des problèmes de flexibilité ou de douleur dans les pieds ou les jambes."
                            }
                        },
                        {
                            "id": "BARRE_APPUI_LIT",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une barre d'appui de lit?",
                            "help": {
                                "description": "Une barre d'appui de lit peut offrir un soutien supplémentaire au domiciliant pour se lever ou se coucher dans son lit, en particulier s'il a des problèmes de mobilité ou de stabilité."
                            }
                        },
                        {
                            "id": "RELEVE_BUSTE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un relève-buste?",
                            "help": {
                                "description": "Un relève-buste peut aider le domiciliant à s'asseoir plus confortablement dans son lit et peut aider à soulager les problèmes respiratoires ou digestifs associés à la position couchée."
                            }
                        },
                        {
                            "id": "PARURE_DRAPS",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une parure de draps?",
                            "help": {
                                "description": "Une parure de draps peut améliorer le confort et la qualité de sommeil du domiciliant, et peut aider à maintenir une température corporelle confortable pendant la nuit."
                            }
                        },
                        {
                            "id": "LAMPE_LECTURE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une lampe de lecture?",
                            "help": {
                                "description": "Une lampe de lecture peut aider le domiciliant à lire plus facilement depuis son lit, en particulier s'il a des problèmes de vue ou de faible luminosité dans sa chambre."
                            }
                        },
                        {
                            "id": "OREILLER",
                            "title": "Le domiciliant a-t-il besoin d'un oreiller?",
                            "type": "produit",
                            "non_louable": true,
                            "help": {
                                "description": "Un oreiller peut aider le domiciliant à soutenir sa tête et son cou pour un sommeil plus confortable. Il existe des oreillers spécialement conçus pour répondre aux besoins des personnes ayant des problèmes de douleur ou de mobilité."
                            }
                        },
                        {
                            "id": "VETEMENTS_HABILLAGE_FACILE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin de vêtements à habillage facile?",
                            "help": {
                                "description": "Des vêtements à habillage facile peuvent être utiles pour le domiciliant s'il a des problèmes de mobilité ou s'il a besoin de l'aide d'un soignant pour s'habiller. Ils peuvent également être pratiques pour les changements de couches ou les toilettes au lit."
                            }
                        },
                        {
                            "id": "LOUPE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une loupe?",
                            "help": {
                                "description": "Une loupe peut aider le domiciliant à lire plus facilement, en particulier s'il a des problèmes de vue ou de perception des détails."
                            }
                        },
                        {
                            "id": "LUNETTES_LOUPE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une paire de lunettes loupe?",
                            "help": {
                                "description": "Une paire de lunettes loupe peut aider le domiciliant à voir plus facilement les détails, en particulier s'il a des problèmes de vue ou de perception des détails."
                            }
                        },
                        {
                            "id": "PORTE_REVUES",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un porte-revues?",
                            "help": {
                                "description": "Un porte-revues peut être utile pour permettre au domiciliant de lire ou de regarder des magazines ou des journaux depuis son lit ou son fauteuil de repos."
                            }
                        }
                    ]
                },
                {
                    "id": "salle_de_bain",
                    "name": "Salle de bain",
                    "image": "https://images.unsplash.com/photo-1584622650111-993a426fbf0a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
                    "questions": [
                        {
                            "id": "BARRE_MAINTIEN",
                            "type": "produit",
                            "non_louable": true,
                            "priorite": 1,
                            "title": "Le domiciliant a-t-il besoin d'une barre de maintien?",
                            "mesure": "fixe? Ou relevable?",
                            "help": {
                                "description": "Une barre de maintien peut être utile pour offrir un soutien supplémentaire au domiciliant lors de ses déplacements dans la maison ou dans la salle de bain, en particulier s'il a des problèmes de mobilité ou de stabilité."
                            }
                        },
                        {
                            "id": "SIEGE_DOUCHE",
                            "type": "produit",
                            "priorite": 1,
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un siège de douche?",
                            "help": {
                                "description": "Un siège de douche peut offrir un support supplémentaire au domiciliant lors de sa douche, en particulier s'il a des problèmes de mobilité ou de stabilité."
                            }
                        },
                        {
                            "id": "LIT_DOUCHE",
                            "type": "produit",
                            "non_louable": false,
                            "title": "Le domiciliant a-t-il besoin d'un lit douche?",
                            "help": {
                                "no_image_auto": true,
                                "description": "Un lit douche peut être utile pour permettre au domiciliant de prendre une douche sans avoir à se déplacer ou à être transféré d'un lit à un fauteuil de douche."
                            }
                        },
                        {
                            "id": "BAIGNOIRE_OUVERTURE_LATERALE",
                            "type": "produit",
                            "non_louable": false,
                            "title": "Le domiciliant a-t-il besoin d'une baignoire à ouverture latérale?",
                            "help": {
                                "description": "Une baignoire à ouverture latérale peut permettre au domiciliant d'entrer et de sortir de la baignoire plus facilement et en toute sécurité, en particulier s'il a des problèmes de mobilité ou de flexibilité."
                            }
                        },
                        {
                            "id": "DOUCHE_ITALIENNE",
                            "type": "produit",
                            "non_louable": false,
                            "title": "Le domiciliant a-t-il besoin d'une douche à l'italienne?",
                            "help": {
                                "no_image_auto": true,
                                "description": "Une douche à l'italienne peut offrir un accès facile et sûr à la douche pour le domiciliant, en particulier s'il a des problèmes de mobilité ou de stabilité. Elle peut également offrir un design esthétique pour la salle de bain."
                            }
                        },
                        {
                            "id": "BONNETS_BAIN_LAVANTS",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin de bonnets de bain lavants sans rinçage?",
                            "help": {
                                "no_image_auto": true,
                                "description": "Les bonnets de bain lavants sans rinçage peuvent être utiles pour aider le domiciliant à se laver les cheveux sans avoir à prendre une douche complète."
                            }
                        },
                        {
                            "id": "GANTS_TOILETTE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin de gants de toilete?",
                            "help": {
                                "description": "Les gants de toilette peuvent être utiles pour aider le domiciliant à se laver le corps sans avoir à prendre une douche complète."
                            }
                        },
                        {
                            "id": "SIEGE_BAIN_PIVOTANT",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un siège de bain pivotant?",
                            "help": {
                                "description": "Un siège de bain pivotant peut aider le domiciliant à entrer et sortir de la baignoire plus facilement et en toute sécurité, en particulier s'il a des problèmes de mobilité ou de flexibilité."
                            }
                        },
                        {
                            "id": "PLANCHE_BAIN",
                            "type": "produit",
                            "priorite": 1,
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une planche de bain?",
                            "help": {
                                "description": "Une planche de bain peut offrir un support supplémentaire au domiciliant lorsqu'il prend sa douche ou son bain, en particulier s'il a des problèmes de mobilité ou de stabilité."
                            }
                        },
                        {
                            "id": "TABOURET_DOUCHE",
                            "type": "produit",
                            "priorite": 1,
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un tabouret de douche?",
                            "help": {
                                "description": "Un tabouret de douche peut offrir un soutien supplémentaire au domiciliant lorsqu'il prend sa douche, en particulier s'il a des problèmes de mobilité ou de stabilité."
                            }
                        },
                        {
                            "id": "TAPIS_ANTIGLISSE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un tapis anti-glisse?",
                            "help": {
                                "description": "Un tapis anti-glisse peut aider à prévenir les chutes dans la salle de bain en offrant une surface antidérapante pour le domiciliant lorsqu'il est mouillé."
                            }
                        },
                        {
                            "id": "MARCHE_PIED",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un marche pied?",
                            "help": {
                                "description": "Un marche pied peut aider le domiciliant à atteindre des objets en hauteur dans la salle de bain ou dans la cuisine, en particulier s'il a des problèmes de mobilité ou de taille."
                            }
                        },
                        {
                            "id": "ELEVATEUR_BAIN",
                            "type": "produit",
                            "non_louable": false,
                            "title": "Le domiciliant a-t-il besoin d'un élévateur de bain?",
                            "help": {
                                "description": "Un élévateur de bain peut aider le domiciliant à entrer et sortir de la baignoire plus facilement et en toute sécurité, en particulier s'il a des problèmes de mobilité ou de flexibilité."
                            }
                        }
                    ]
                },
                {
                    "id": "CUISINE",
                    "name": "Cuisine",
                    "image": "https://images.unsplash.com/photo-1556911220-bff31c812dba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1268&q=80",
                    "questions": [
                        {
                            "id": "TABLE_REGLABLE",
                            "type": "produit",
                            "non_louable": false,
                            "title": "Le domiciliant a-t-il besoin d'une table réglable en hauteur / élévateur de table?",
                            "help": {
                                "description": "Une table réglable en hauteur ou un élévateur de table peut permettre au domiciliant de manger ou de travailler confortablement tout en étant assis dans son fauteuil ou son lit, en particulier s'il a des problèmes de mobilité ou de flexibilité."
                            }
                        },
                        {
                            "id": "PLAN_TRAVAIL_REGLABLE",
                            "type": "produit",
                            "non_louable": false,
                            "title": "Le domiciliant a-t-il besoin d'un plan de travail réglable en hauteur?",
                            "help": {
                                "description": "Un plan de travail réglable en hauteur peut permettre au domiciliant de travailler confortablement tout en étant debout, en particulier s'il a des problèmes de mobilité ou de flexibilité."
                            }
                        },
                        {
                            "id": "COUVERTS_ADAPTES",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin de couverts adaptés?",
                            "help": {
                                "description": "Des couverts adaptés peuvent faciliter la prise de repas pour le domiciliant en cas de difficultés de préhension ou de coordination des mouvements."
                            }
                        },
                        {
                            "id": "BAVOIR",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un bavoir?",
                            "help": {
                                "description": "Un bavoir peut être utile pour aider le domiciliant à éviter de se salir pendant les repas, en particulier s'il a des problèmes de coordination des mouvements."
                            }
                        },
                        {
                            "id": "OUVRE_BOITE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un ouvre-boite?",
                            "help": {
                                "description": "Un ouvre-boite peut aider le domiciliant à ouvrir les boîtes de conserve en cas de difficultés de préhension ou de force."
                            }
                        },
                        {
                            "id": "VERRE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un verre?",
                            "help": {
                                "description": "Un verre adapté peut faciliter la prise de boisson pour le domiciliant en cas de difficultés de préhension ou de coordination des mouvements."
                            }
                        },
                        {
                            "id": "ASSIETTE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une assiette?",
                            "help": {
                                "description": "Une assiette adaptée peut faciliter la prise de repas pour le domiciliant en cas de difficultés de préhension ou de coordination des mouvements."
                            }
                        },
                        {
                            "id": "PINCE_A_PREHENSION",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une pince à préhension?",
                            "help": {
                                "description": "Une pince à préhension peut aider le domiciliant à saisir des objets hors de portée ou difficiles à attraper, en particulier s'il a des problèmes de mobilité ou de force."
                            }
                        },
                        {
                            "id": "PILLULIER",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un pilulier?",
                            "help": {
                                "description": "Un pilulier peut aider le domiciliant à organiser et à prendre ses médicaments correctement, en particulier s'il a des problèmes de mémoire ou de coordination."
                            }
                        },
                        {
                            "id": "ECRASE_COMPRIMES",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un écrase-comprimés?",
                            "help": {
                                "description": "Un écrase-comprimés peut aider le domiciliant à écraser ses comprimés pour faciliter la prise de médicaments, en particulier s'il a des problèmes de déglutition."
                            }
                        },
                        {
                            "id": "LOUPE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une loupe?",
                            "help": {
                                "description": "Une loupe peut aider le domiciliant à lire des petits caractères sur des livres, des journaux, des étiquettes ou des documents, en particulier s'il a des problèmes de vision."
                            }
                        }
                    ]
                },
                {
                    "id": "WC_HYGIENE",
                    "name": "WC et hygiène",
                    "image": "https://www.hexaplus.fr/img/news/hexastat/audit/bannieres/wc.jpg",
                    "questions": [
                        {
                            "id": "REHAUSSE_WC",
                            "type": "produit",
                            "non_louable": false,
                            "title": "Le domiciliant a-t-il besoin d'un réhausse WC?",
                            "help": {
                                "description": "Un réhausse WC peut aider le domiciliant à utiliser les toilettes plus facilement et plus confortablement, en particulier s'il a des problèmes de mobilité ou de flexibilité."
                            }
                        },
                        {
                            "id": "BARRE_MURALE",
                            "type": "produit",
                            "non_louable": false,
                            "title": "Le domiciliant a-t-il besoin d'une barre murale?",
                            "help": {
                                "description": "Une barre murale peut aider le domiciliant à se lever ou à s'asseoir plus facilement dans les toilettes ou la douche, en particulier s'il a des problèmes de mobilité ou de force."
                            }
                        },
                        {
                            "id": "CHAISE_GARDE_ROBE",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'une chaise garde-robe?",
                            "help": {
                                "description": "Une chaise garde-robe peut être utile pour aider le domiciliant à utiliser les toilettes plus facilement et plus confortablement, en particulier s'il a des problèmes de mobilité ou de flexibilité."
                            }
                        },
                        {
                            "id": "PROTEGE_SEAU_BASSIN",
                            "type": "produit",
                            "non_louable": true,
                            "title": "Le domiciliant a-t-il besoin d'un protège-seau et bassin?",
                            "help": {
                                "description": "Un protège-seau et bassin peut aider à éviter les fuites et les accidents lors de l'utilisation des toilettes ou des bassins de lit, en particulier s'il y a des problèmes d'incontinence."
                            }
                        },
                        {
                            "id": "BARRE_APPUI",
                            "type": "produit",
                            "non_louable": false,
                            "title": "Le domiciliant a-t-il besoin d'une barre d'appui?",
                            "help": {
                                "description": "Une barre d'appui peut aider le domiciliant à se lever ou à s'asseoir plus facilement dans les toilettes ou la douche, en particulier s'il a des problèmes de mobilité ou de force."
                            }
                        },
                        {
                            "id": "CADRE_TOILETTE",
                            "type": "produit",
                            "non_louable": false,
                            "title": "Le domiciliant a-t-il besoin d'un cadre de toilette?",
                            "help": {
                                "description": "Un cadre de toilette peut aider le domiciliant à se lever ou à s'asseoir plus facilement dans les toilettes, en particulier s'il a des problèmes de mobilité ou de force."
                            }
                        }
                    ]
                },
                
            ]
        
    
                    
               




            


                
        };
     

    },
    created() {
/*
        (async () => {
            await worker.load();
            await worker.loadLanguage('eng');
            await worker.initialize('eng');
            const { data: { text } } = await worker.recognize('https://tesseract.projectnaptha.com/img/eng_bw.png');
            console.log(text);
            await worker.terminate();
        })();
*/
  },
    "name": "Audit",


}


</script>