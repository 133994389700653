import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import Maintenance from "./components/Maintenance.vue";
import ParcIndividuel from "./components/ParcIndividuel.vue";
import CreationParc from "./components/CreationParc.vue";
import { createToaster } from "@meforma/vue-toaster";
import Sav from "./components/Sav.vue";
import Chat from "./components/Chat.vue";
import Hexaplus from "./components/Hexaplus.vue";
import Audit from "./components/audit/Audit.vue";
import AuditHistorique from "./components/audit/AuditHistorique.vue";

import LignesLocation from "./components/LignesLocation.vue";
import ParcRdv from "./components/ParcRdv.vue";

const toaster = createToaster({ position: 'top' });
// lazy-loaded
const Parc = () => import("./components/Parc.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")

const routes = [
  
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/maintenance/:id/:code",
    component: Maintenance,
    name:"maintenance"
  },
    {
    path: "/maintenance/:id",
    component: Maintenance,
    name:"maintenanceNoCode"
  },
    {
    path: "/CreationParc/:code",
    component: CreationParc,
  },
    {
      path: "/parc/:id/:code",
      name:"parc",
    component: ParcIndividuel,
  },
    {
      path: "/parc/:id/",
      name:"parcNoCode",
    component: ParcIndividuel,
  },
  {
    path: "/parc-rdv",
    component: ParcRdv,
    name:"parcRdv"
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/parc-liste",
    name: "parcliste",
    // lazy-loaded
    component: Parc,
  },
  {
    path: "/sav/:id",
    name: "sav",
    // lazy-loaded
    component: Sav,
  },
  {
    path: "/chat/",
    name: "chat",
    // lazy-loaded
    component: Chat,
  },
    {
    path: "/hexaplus/",
    name: "hexaplus",
    // lazy-loaded
    component: Hexaplus,
  },
        {
    path: "/ligneslocation/",
    name: "ligneslocation",
    // lazy-loaded
    component: LignesLocation,
  },
      {
    path: "/audit",
    name: "audit",
    // lazy-loaded
    component: Audit,
  },
      {
    path: "/audit-historique",
    name: "auditHistorique",
    // lazy-loaded
    component: AuditHistorique,
  },


  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes,
});

 router.beforeEach((to, from, next) => {
   const publicPages = ['/login', '/register'];
   const authRequired = !publicPages.includes(to.path);
   const loggedIn = localStorage.getItem('user');

   // trying to access a restricted page + not logged in
   // redirect to login page
   if (authRequired && !loggedIn) {
          toaster.info('Veuillez vous connecter');

     next('/login');
   } else {
     next();
   }
 });

export default router;