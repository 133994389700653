<template>

<Title :title="title" />

      <modal-confirm modal-id="exampleModal" title="Changement de résident" v-if="showModal" @zmodal-close="handleModalClose" 
      @zmodal-save="showModal = false; changerResidentLocationGo()"
      showSaveButton="true"
      >
      <div class="form-group">
        <label for="exampleFormControlSelect1" class="form-label">Changer de résident</label><br/>
      <select class="form-select " v-model="selectedResident" @change="changeResident">
        <option v-for="resident in residents" :key="resident.id" :value="resident.id">
          {{ resident.nom }} -  {{ resident.nom_service }}
        </option>
      </select>
      </div>
    <!--
      <button class="btn btn-primary" @click="showModal = false; changerResidentLocationGo()">Enregistrer</button>
    -->
      </modal-confirm>


   <!--
  <VueFinalModal v-model="showModal" name="example"      classes="modal-container"
        content-class="modal-content">
    <h4>Changement de résident</h4><br/>

    <div class="form-group">
      <label for="exampleFormControlSelect1" class="form-label">Changer de résident</label><br/>
    <select class="form-select " v-model="selectedResident" @change="changeResident">
      <option v-for="resident in residents" :key="resident.id" :value="resident.id">
        {{ resident.nom }} -  {{ resident.nom_service }}
      </option>
    </select>
    <br />
    </div>
  <button class="btn btn-primary" @click="showModal = false; changerResidentLocationGo()">Enregistrer</button>


          <button class="modal-close" @click=" showModal = false ">
            <i class="fa-thin fa-times"></i>
          </button>
    </VueFinalModal>
-->

  




  <div class="container">
<div class="row">
      <div class="col-md-4 offset-md-4">

<div class="card">
  <div class="card-header">
        <b>Scan Qrcode:</b> {{currentParc.hs_lpd_scan}}<br />

  </div>
  <div class="card-body">
    <p class="card-text"><b>N° série:</b>  {{currentParc.hs_lpd_serie}}<br/>
    <b>N° Parc:</b>  {{currentParc.hs_lpd_parc}}<br/>
        <b>Etablissement:</b> {{currentParc.int_raisonsociale}}<br/>
        <b>Résident:</b> {{currentParc.lit_occupant}} {{currentParc.lit_chambre}}<br />
        <b>Statut établissement :</b> {{currentParc.hs_LPS_designation}}<br/>
        <b>Ligne établissement :</b> {{currentParc.hs_lp_id}}<br />
        <b> Maintenance : </b>{{currentParc.hs_lpd_derniere_maintenance}}<br/>

    </p>
<ul class="list-group list-group-flush">
    <li class="list-group-item">
<router-link v-bind:to="'/maintenance/'+currentParc.hs_lpd_id + '/'+currentParc.hs_lpd_scan " class="btn btn-block btn-primary" ><i class="fa-thin fa-toolbox"></i>
   Effectuer maintenance</router-link>
    </li>
    <li v-if="currentParc.hs_lp_id" class="list-group-item"><router-link v-bind:to="'/sav/'+currentParc.hs_lp_id" class="btn btn-block btn-primary" ><i class="fa-thin fa-screwdriver-wrench"></i> Effectuer SAV</router-link></li>
          <li v-if="currentParc.hs_lp_id" class="list-group-item text-muted"> <button
              @click="changerResidentLocation(currentParc)" class="btn btn-dark btn-block "><i
                class="fa-thin fa-users-medical"></i> Changer de résident</button></li>
    <li v-if="currentParc.hs_lp_id" class="list-group-item text-muted"><button @click="arreterLocation(currentParc)" class="btn btn-danger btn-block "><i class="fa-thin fa-stop"></i> Arrêter location</button></li>
      <li v-if="!currentParc.hs_lpd_current_parc" class="list-group-item text-muted">       Les Savs ne sont réalisables que sur des DM utilisés</li>

  </ul>
  <span v-if="currentParc.new  || currentParc.sync" class="badge  bg-secondary text-light"><i class='fa fa-cloud-upload'></i> En attente de synchronisation</span>

  </div>

</div>

      </div>

</div>

  </div>



</template>

<script>
import UserService from "../services/user.service";
import {savePost} from "../store/post";
import { createToaster } from "@meforma/vue-toaster";
import { VueFinalModal } from 'vue-final-modal'
import { ModalsContainer, useModal } from 'vue-final-modal'
import ModalConfirm from "./sub/ModalConfirm.vue";

const toaster = createToaster({ position: 'top' });
export default {
  components: {
    ModalConfirm
  },
 
  props: {
  },
  methods: {

    handleModalClose() {
      this.showModal = false;
    },

//methods changerResidentLocation to change resident location, with a modal opening, asking for the new resident
    changerResidentLocation: function (parc) {
      
            this.showModal = true;

      
      
      

    },


    changerResidentLocationGo(k) {
      //get lit_occupant from selectedResident rin esidents

      var inforesident = this.residents.find(x => x.id === this.selectedResident);
      this.currentParc.lit_occupant = inforesident.nom;
      this.currentParc.lit_id = inforesident.id;
      this.currentParc.nom_service = inforesident.nom_service;
      const data = { hs_lp_id: this.currentParc.hs_lp_id, hs_lp_patient: this.currentParc.lit_id };
      this.post.installerLocation.push(data);

      //savePost();
      toaster.show('Changement résident Enregistré !');
    },  


arreterLocation(k){
k.arretLocations=1;
k.sync=1;
const data = {hs_lp_id:k.hs_lpd_current_parc};
post.arretLocations.push(data);
savePost();
  toaster.show('Enregistré');
  },

  },
  name: "ParcIndividuel",
  data() {
    return {
      content: "",
      selectedResident:{},
      currentParc:[],
      title: '',
      showModal: false,
    };
  },

  computed: {

    //this.$store.state.dataPost.monObjet
    post: function(){
      return this.$store.state.dataPost.monObjet;
    },


    selectedResident: {
      get() {
        console.log(JSON.parse(JSON.stringify(this.currentParc)));

        return this.currentParc.hs_lp_patient;
      },
      set(value) {
        this.selectedResident = value;
      }
    },
    residents() {
      //filter resident based on hs_lp_int 
      //console.log(this.$store.state.dataPost.monObjet.residents[1]);
      console.log(JSON.parse(JSON.stringify(this.$store.state.dataPost.monObjet.residents)));
      return this.$store.state.dataPost.monObjet.residents.filter(resident => resident.id_intervenant === this.currentParc.hs_lp_int);
      return this.$store.state.residents;
    },


    currentUser() {
      return this.$store.state.dataPost.monObjet;
    }
  },

  mounted() {
    const currentId = this.$route.params.id;

    // find in currentUser where hs_lpd_id = this.$route.params.id
     this.currentParc = this.$store.state.dataPost.monObjet.hexastat_parc.find(function(k){
       console.log(currentId);
       return k.hs_lpd_id == currentId;
     }.bind(this));

    this.title = this.currentParc.hs_lpd_id;
if (this.currentParc.new)
{
  this.title="Parc Individuel #"+" (Nouveau)";
}

    UserService.getUserBoard().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>
