<template>
  <Title title="Scannez un QR code de parc"
    subtitle="Veuillez scanner le Qrcode du parc à l'aide de votre appareil mobile pour accéder aux informations"
    :image="imageTitle" />

  <div class="form-group">
    <label for="text">Entrez le code</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <i class="fa fa-qrcode"></i>
        </div>
      </div>
      <input v-model="qrCodeMannuel" type="text" class="form-control" aria-describedby="textHelpBlock">
    </div>
    <span id="textHelpBlock" class="form-text text-muted">Si vous n'arrivez pas à scanner le code.</span>
  </div>
  <div class="form-group">
    <button name="submit" @click="onDecodeManuel" class="btn btn-primary">Valider</button>
  </div>
  <qrcode-stream @decode="onDecode"></qrcode-stream><br />
  <span class="text-muted"><i class="fa-thin fa-info-circle"></i> Version webapp {{version.version}} {{ version.date
    }}</span>
  <i>Mise à jour des données du {{ lastSyncDate }}</i>
  <br /><br /><br />
  <div class="form-group mt-2 mb-2 d-flex">
    <button name="submit" @click="GoFullScreen" class="btn btn-primary"><i class="fa-thin fa-expand"></i> Mettre en
      plein écran</button>
    <button name="logout" @click="logOut" class="btn btn-primary ml-1"><i class="fa-thin fa-sign-out"></i> Se
      déconnecter</button>
    <button name="installPwa" @click="installPwa" class="btn btn-primary ml-1"><i class="fa-thin fa-download"></i>
      Installer l'application</button>
    <button name="forceReinstall" @click="forceReinstall" class="btn btn-primary ml-1"><i
        class="fa-thin fa-refresh"></i>
      Installer mise à jour</button>

  </div>

  <div style="border: 1px solid #000; width: 100%; height: 20px;">
    <div id="storageProgressBar" style="background-color: #4caf50; width: 0; height: 100%;"></div>
  </div>
</template>

<script>
import beep from "../assets/sounds/beep.mp3";
import { createToaster } from "@meforma/vue-toaster";
import PWAPrompt from './sub/PWAPrompt.vue';
import Version from "../version.js";

import imageTitle from '@/assets/images/qrcode-scanning.webp';

const toaster = createToaster({ position: 'top' });

export default {
  name: "Home",
  data() {
    return {
      content: "Scanner un Qrcode",
      currentParc: false,
      imageTitle: imageTitle,
      qrCodeMannuel: '',
      version: Version,
      deferredPrompt: null

    };
  },
  methods: {
    forceReinstall()
    {

      //faisons un http get sur le fichier version.js
      //si la version est différente de la version actuelle
      //on force le cache clear
      //on force le refresh de la page

      alert("Nous allons forcer la mise à jour de l'application");
      //clear all service worker if exists, force cache clear etc
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
      //go to basepage /
      //this.$route.push('/');
      //window.location = '/';
      window.reload();

    },
    installPwa() {
      // Vérifier si le deferredPrompt est défini
      if (this.deferredPrompt) {
        // Afficher la invite d'installation
        this.deferredPrompt.prompt();
        // Réinitialiser le deferredPrompt
        this.deferredPrompt = null;
        // Afficher une alerte pour indiquer que la PWA est en train d'être installée
        alert('L\'application est en train d\'être installée');
      }
      else {
        // Afficher une alerte pour indiquer que la PWA ne peut pas être installée
        alert('L\'application ne peut pas être installée');
      }
    },


    GoFullScreen()
    {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        //if safari ios 
        if (navigator.standalone) {
          alert('Vous êtes déjà en plein écran');
          return false;
        }

        document.documentElement.requestFullscreen();
      }
    },
    onDecodeManuel()
    {
    this.onDecode(this.qrCodeMannuel);
  },
    logOut()
    {
      this.$store.dispatch("auth/logout");
      this.$router.push('/login');
      
      },
    onDecode(data) {

      data=data.split("/");
      data = data[data.length - 1];
      //upercase data
      data=data.toUpperCase();
      this.content = data;
      console.log(this.$store.state);
      this.currentParc = this.$store.state.dataPost.monObjet.hexastat_parc.find(function(k){
      return k.hs_lpd_scan == data;
    });

if (!data)
{
  return false ;
}
else if (this.currentParc)
{
//redirect to parc/:id
let sound =beep;
var audio = new Audio(sound);
audio.play();
this.$router.push('/parc/'+this.currentParc.hs_lpd_id + '/'+this.currentParc.hs_lpd_scan );
}
else 
{
  toaster.show('Création du parc en cours');
  this.$router.push('/creationParc/'+data);

}





    },


async  estimateStorageUsage() {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    const estimate = await navigator.storage.estimate();
    const usage = estimate.usage; // espace utilisé en octets
    const quota = estimate.quota; // espace total disponible en octets
    const percentageUsed = (usage / quota) * 100;

    console.log(`Espace utilisé: ${usage} octets`);
    console.log(`Quota total: ${quota} octets`);
    console.log(`Pourcentage utilisé: ${percentageUsed.toFixed(2)}%`);

    // Mettre à jour une barre de progression avec le pourcentage utilisé
    document.getElementById('storageProgressBar').style.width = `${percentageUsed}%`;
  } else {
    console.warn('Storage API non supportée dans ce navigateur.');
  }
}

  },
  computed: {

    
    cachePwa() {

      return JSON.parse(JSON.stringify(self.caches));
      self.caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    },
    currentUser() {
      return this.$store.state.dataPost.monObjet;
    },

lastSyncDate() {
      return this.$store.state.dataPost.monObjet.lastSyncDate;
    },

  },

  mounted() {
    // Vérifier si le navigateur prend en charge les PWA
    if ('serviceWorker' in navigator) {
      // Enregistrer le service worker
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        // Le service worker a été enregistré avec succès
        console.log('Service Worker enregistré avec succès :', registration);
        //alert('Service Worker enregistré avec succès');
      }).catch(err => {
        // Échec de l'enregistrement du service worker
        console.error('Échec de l\'enregistrement du Service Worker :', err);
        alert('Échec de l\'enregistrement du Service Worker : ' + err);
      });

      // Vérifier si la PWA peut être installée
      window.addEventListener('beforeinstallprompt', event => {
        // Empêcher le navigateur d'afficher la invite d'installation automatique
        event.preventDefault();
        // Stocker l'événement pour une utilisation ultérieure
        this.deferredPrompt = event;
        // Afficher une alerte pour indiquer que la PWA peut être installée
        alert('Cette application peut être installée sur votre appareil');
      });
    } else {
      // Le navigateur ne prend pas en charge les PWA
      console.warn('Le navigateur ne prend pas en charge les PWA');
      //alert('Le navigateur ne prend pas en charge les PWA');
    }
    this.estimateStorageUsage();

  },
  beforeMount() {
    window.addEventListener('appinstalled', event => {
      // Afficher une alerte pour indiquer que la PWA a été installée avec succès
      console.log('PWA installée avec succès', event);
      alert('Application installée avec succès. Vous pouvez maintenant l\'ouvrir depuis votre écran d\'accueil.');
    });
  },





};


</script>
