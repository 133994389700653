<template>
  <Title :title="title" />

  <div class="card text-center">
    <div class="card-header">
      {{currentParc.produit_designation}}
    </div>
    <div class="card-body">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">Produit : {{currentParc.produit_designation}}</li>

        <li class="list-group-item">N° série : {{currentParc.hs_lpd_serie}}</li>
        <li class="list-group-item">N° Parc : {{currentParc.hs_lpd_parc}}</li>
        <li class="list-group-item">Code Scan : {{currentParc.hs_lpd_scan}}</li>
        <li class="list-group-item">Etablissement : {{currentParc.int_raisonsociale}}</li>

      </ul>
    </div>
    <div class="card-footer text-muted">
    </div>
  </div>


  <br /><br />


  <!-- bootstrap dropdown with fichesDispo -->
  <div class="dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      Choisir un autre questionnaire
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a v-for="fiche in fichesDispo" v-bind:key="fiche.s_id" class="dropdown-item"
        @click="selectFiche(fiche)">{{fiche.s_nom}}</a>
    </div>
  </div>
  <div v-if="dataJson">
    <div class="">
      <div class="lead">
        <h2>{{dataJson.title}}</h2>
      </div>
    </div>
    <div class="" v-for="k in dataJson.pages" :key="k">

      <div class="row" v-for="(kp,index) in k.elements" :key="index">
        <div class="col-md-12 ">

          <div class="img-maintenance" v-if="kp.type=='image'">
            <img style='height:120px' class="img-fluid align-center" v-bind:src="kp.imageLink" />
          </div>


          <div class="form-group" v-if="kp.type=='text'">
            <label v-bind:for="'input'+kp.name">
              <span v-if='kp.title'>{{kp.title}}</span>
              <span v-if='!kp.title'>{{kp.name}}</span>

            </label>
            <input type="text" class="form-control" v-bind:id="'input'+kp.name" v-model="dataMaint[kp.name]">
          </div>

          <div class="card mb-1" v-if="kp.type=='matrix'">

            <div class="card-header">
              <span v-if='kp.title'>{{kp.title}}</span>
              <span v-if='!kp.title'>{{kp.name}}</span>
            </div>

            <div class="card-body">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th></th>

                    <th v-for="(kc) in kp.columns" :key="kc">

                      <span v-if="kc.text">{{kc.text}}</span>
                      <span v-if="!kc.text">{{kc}}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(kr,indexr) in kp.rows" :key="indexr">

                    <td style="max-width:150px">{{kr}}</td>
                    <td v-for="(kc,indexc) in kp.columns" :key="indexc">
                      <input v-if="kc.text" title="ok1" type="radio" v-model="dataMaint[kp.name][kr]" :value="kc.value">
                      <input v-if="!kc.text" title='ok2' type="radio" v-model="dataMaint[kp.name][kr]" :value="kc">


                    </td>
                  </tr>
                </tbody>
              </table>
              <image-upload-resizer-component @imageChange="imageUpload" :upload-if="1 <2" :name="kp.name"
                :name-index="kp.name + '0'" :max-width="512" :max-height="512" multiple accept="image/*"
                capture="camera" doNotResize="['gif', 'svg']">
              </image-upload-resizer-component>
              <image-upload-resizer-component
                :upload-if="dataMaint.photo && dataMaint.photo.find(x => x.nameIndex == kp.name + '0')"
                @imageChange="imageUpload" :name="kp.name" :max-width="512" :max-height="512"
                :name-index="kp.name + '1'" multiple accept="image/*" capture="camera" doNotResize="['gif', 'svg']">
              </image-upload-resizer-component>

            </div>
            <div class="card-footer text-muted">





            </div>
          </div>

          <div class="form-group" v-if="kp.type=='comment'">
            <label v-bind:for="'input'+kp.name">
              <span v-if='kp.title'>{{kp.title}}</span>
              <span v-if='!kp.title'>{{kp.name}}</span>
            </label>
            <textarea type="text" class="form-control" v-bind:id="'input'+kp.name"
              v-model="dataMaint[kp.name]"></textarea>
          </div>

          <div class="form-check form-switch" v-if="kp.type=='boolean'">
            <b>{{ kp.name}}</b><br />
            <input type="radio" v-bind:id="'input' + kp.name+kp.labelTrue" v-model="dataMaint[kp.name]"
              :value="kp.labelTrue">
            <label v-bind:for="'input'+kp.name+kp.labelTrue">
              {{kp.labelTrue}}
            </label>&nbsp;&nbsp;
            <input type="radio" v-bind:id="'input'+kp.name+kp.labelFalse" v-model="dataMaint[kp.name]"
              :value="kp.labelFalse">

            <label v-bind:for="'input'+kp.name+kp.labelFalse">
              {{kp.labelFalse}}
            </label>


          </div>

        </div>

      </div>

    </div>

    <button class="btn btn-primary" v-on:click="save">Enregistrer cette maintenance</button>

  </div>



  <br /><br /><br /><br /><br />
</template>

<script>
import UserService from "../services/user.service";
import {post, savePost} from "../store/post";
import imageUploadResizerComponent from './sub/ImageUpload.vue';


import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: 'top' });


export default {
  watch: {
  },
  components: { imageUploadResizerComponent },

  name: "User",

  data() {
    return {
      content: "",
        currentParc:[],
      currentFiche: [],
  fichesDispo:[],
  dataMaint:{},
  dataJson:false,
  post:post,
  title:''
    };
  },
  methods: {
   save(){


      //UserService.saveMaintenance(this.dataMaint);
      this.$store.state.dataPost.monObjet.maintenance.push(this.dataMaint);

      // //pour le bien du test on push 30 fois
      // for (var i = 0; i < 30; i++)
      // {
      //   this.$store.state.dataPost.monObjet.maintenance.push(this.dataMaint);
      // }

      this.$store.commit('setMonObjet', this.$store.state.dataPost.monObjet);
          //this.$router.push("/parc/" + this.$route.params.id + "/" + this.$route.params.code );
          toaster.success('Maintenance enregistrée !');
          //get current date format english
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();
          today = yyyy + '-' + mm + '-' + dd;
          this.currentFiche.hs_lpd_derniere_maintenance = today;
          //On indique que la dernière maintenance a été effectué...
      this.$store.state.dataPost.monObjet.hexastat_parc.find(x => x.hs_lpd_id == this.currentParc.hs_lpd_id).hs_lpd_derniere_maintenance = today;
      this.$store.state.dataPost.monObjet.hexastat_parc.find(x => x.hs_lpd_id == this.currentParc.hs_lpd_id).hs_lpd_date_rdv = null;
      this.$store.state.dataPost.monObjet.hexastat_parc.find(x => x.hs_lpd_id == this.currentParc.hs_lpd_id).hs_lpd_rdv_utilisateur = null;
          //savePost();




    },

    selectFiche(fiche){
      this.currentFiche=fiche;
      this.dataJson=fiche.dataJson;
      this.title=fiche.s_nom;
      this.dataMaint = {};
      this.changeFiche();
      return;
      this.dataJson.pages.forEach((k)=>{
        this.dataMaint[k.name]='';
      });
    },
    changeFiche()
    {
      // On veut la maintenance du lit 
      //  this.currentFiche = this.$store.state.dataPost.monObjet.hexastat_maintenance[1];
      if (!this.currentParc) return;
      this.title = "Maintenance " + this.currentParc.hs_lpd_id;
      if (this.currentParc.new) {
        this.title = "Maintenance ";
      }


      this.dataJson = JSON.parse(this.currentFiche.s_json);


      // set object to all dataJson pages elements where type=matrix
      for (var k in this.dataJson.pages) {
        for (var kk in this.dataJson.pages[k].elements) {
          if (this.dataJson.pages[k].elements[kk].type == 'matrix') {
            this.dataMaint[this.dataJson.pages[k].elements[kk].name] = {};
            for (var kkk in this.dataJson.pages[k].elements[kk].rows) {
              // this.dataMaint[this.dataJson.pages[k].elements[kk].rows[kkk]]="Oui";
              let tmp = (this.dataJson.pages[k].elements[kk].rows[kkk]);
              this.dataMaint[this.dataJson.pages[k].elements[kk].name][tmp] = 'Oui';
            }


          }
        }
      }

      this.dataMaint.Etablissement = this.currentParc.int_raisonsociale;
      this.dataMaint.numero_identification = this.currentParc.hs_lpd_serie;
      this.dataMaint.Chambre = this.currentParc.lit_occupant;

      this.dataMaint.parc = this.currentParc.hs_lpd_id;
      this.dataMaint.idFormulaire = this.currentFiche.s_id;
      this.dataMaint.id_type = this.currentParc.hs_lpd_id;
      this.dataMaint.type = 'maintenance';
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      console.log(files);
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.file = e.target.result;
      };
      reader.readAsDataURL(file);
      
     // this.dataMaint.image = file;
    },
    imageUpload(info) {
      var name = info.name;
      if (info.removed) {
        this.dataMaint.photo = this.dataMaint.photo.filter(
          (image) => {
            return image.nameIndex !== info.nameIndex;
          }
        );
        //delete this.dataMaint.images[name]
       // this.dataMaint.images[name].splice(info.removed, 1);
        return;
      }


      if (!this.dataMaint.photo) {
        this.dataMaint.photo = [];
      }
      this.dataMaint.photo.push({name: info.name, content:info.src, type:'jpg', nameIndex:info.nameIndex});

    },
  },
  mounted() {

    const currentId = this.$route.params.id;
    this.fichesDispo = this.$store.state.dataPost.monObjet.hexastat_maintenance;
    console.log(this.fichesDispo);
    console.log(currentId);
    console.log(this.$store.state.dataPost.monObjet.hexastat_parc);

//set currentParc = parc with id=currentId
    this.currentParc = this.$store.state.dataPost.monObjet.hexastat_parc.find((k) => {
      console.log('ID',k.hs_lpd_id);
      return k.hs_lpd_id == currentId;
    });

     this.$store.state.dataPost.monObjet.hexastat_parc.find(function (k) {
      console.log(k.hs_lpd_id);
           if (k.hs_lpd_id == currentId) {
        
if (k.produit_designation.indexOf('lit ')>0)
{
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 304);
}
else if (k.produit_designation.indexOf('matelas')>0)
{
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 301);
}
else if (k.produit_designation.indexOf('lève-Patient')>0)
{
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 305);
}
else if (k.produit_designation.indexOf('lève Patient')>0)
{
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 305);
}
else if (k.produit_designation.indexOf('verticalisateur')>0)
{
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 300);
}
else if (k.produit_designation.indexOf('déambulateur')>0)
{
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 315);
}
else if (k.produit_designation.indexOf('deambulateur')>0)
{
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 315);
}
else if (k.produit_designation.indexOf('pied')>0)
{
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 313);
}
else if (k.produit_designation.indexOf('coquille')>0)
{
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 303);
}
else if (k.produit_designation.indexOf('fauteuil')>0)
{
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 302);
}
else if (k.produit_designation.indexOf('douche')>0)
{
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 312);
}
else {
  this.currentFiche =  this.$store.state.dataPost.monObjet.hexastat_maintenance.find(x => x.s_id == 306);


}





      }


       return k.hs_lpd_id == currentId;
     }.bind(this));
   
    this.changeFiche();




  },
};


document.onreadystatechange = () => {
  if (document.readyState == "complete") {
    console.log('Page completed with image and files!')






    // fetch to next page or some code
  }
}

</script>
<style>
.table th { word-wrap: break-word; font-size:10px; padding:1px;}
.table td { font-size:12px;}
</style>