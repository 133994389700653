<template>
    <div class="modal open" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalTitle">{{ title }}</h5>
                    <button @click="$emit('zmodal-close')" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('zmodal-close')" data-dismiss="modal">Fermer</button>
                    <button v-if="showSaveButton" type="button" class="btn btn-primary" @click="$emit('zmodal-save')">Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .modal {
        display: block;
        padding-right: 0px !important;
    }
</style>

<script>
export default {
    props: {
        modalId: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        showSaveButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        save() {
            // Fonction pour enregistrer les modifications
        }
    }
};
</script>
