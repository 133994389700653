<template>
<Title title="Echanges  / Chat " />
<div class="row">
    <div class="col-md-12">
        <div class="alert alert-info">Echangez et partagez vos questions et réponses à la communauté SAV & Maintenance du réseau.</div>
    </div>
</div>

<iframe src="https://deadsimplechat.com/3OFHW5dAV" width="100%" height="600px"></iframe>


</template>



<script>
import UserService from "../services/user.service";
import imageUploadResizerComponent from './sub/ImageUpload.vue';
import {post} from "../store/post";



export default {
  methods: {

  


      

  },
  created () {

console.log(this.$store.state.auth);
this.userName=  this.$store.state.dataPost.monObjet.prenom + " "+ this.$store.state.dataPost.monObjet.nom ;
this.company= this.$store.state.dataPost.monObjet.raisonsociale;
  },

  data() {
    return {
      searchQuery: '',
      message:'',
      userName:'',
      raisonsociale:''
    }
  },

  name: 'Chatc',
  computed: {
      messages () {
          return this.$store.state.dataPost.monObjet.chat;
      },
    currentUser() {
      return this.$store.state.dataPost.monObjet;
    },
    


}
}

</script>